import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import ShowAnswers from "../../../ShowAnswers";
import { ErrorBoundary } from "react-error-boundary";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const getParsedQuestions = (jsonObj) => {
  try {
    const parsed = JSON.parse(jsonObj);
    return parsed;
  } catch (error) {
    return null;
  }
};

const CompletionResults = ({ data }) => {
  const parsedQuestions = getParsedQuestions(data?.quiz__questions);

  return (
    <Wrapper>
      <ErrorBoundary
        fallback={
          <div
            style={{
              height: 200,

              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              borderRadius: 4,
              background: "#eeeeee88",
            }}
          >
            <span style={{ fontSize: 14, fontWeight: "500" }}>
              Error encountered while parsing your answers.
            </span>
            <span style={{ fontSize: 13, color: "darkgrey" }}>
              This module may be too old for this feature.
            </span>
          </div>
        }
      >
        <ShowAnswers
          questions={parsedQuestions.questions}
          userAnswers={data.user_answers}
        />
      </ErrorBoundary>
    </Wrapper>
  );
};

export default CompletionResults;

CompletionResults.propTypes = {
  data: PropTypes.object.isRequired,
};
