import React from "react";
import { InputAdornment, MenuItem } from "@mui/material";
import { Map } from "react-feather";
import { CustomTextField } from "../../../styles/forms";

const LocationSelect = ({
  locations,
  touched,
  handleChange,
  handleBlur,
  errors,
  values,
  disabled = false,
}) => {
  return (
    <CustomTextField
      select
      label="Location At Company"
      name="location"
      variant="outlined"
      value={values.location}
      color="secondary"
      onBlur={handleBlur}
      error={Boolean(touched.location && errors.location)}
      onChange={handleChange}
      helperText={touched.location && errors.location}
      fullWidth
      disabled={disabled}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Map style={{ color: disabled ? "darkgrey" : "black" }} />
          </InputAdornment>
        ),
      }}
      sx={{
        mb: 4,
      }}
    >
      {locations.map((loc) => (
        <MenuItem value={loc.id} key={loc.id}>
          {loc.name}
        </MenuItem>
      ))}
    </CustomTextField>
  );
};

export default LocationSelect;
