import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { addLogoString } from "../../../helpers";
import { ArrowRight, Check } from "react-feather";

const Wrapper = styled.div`
  width: 100%;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  box-shadow: 0px 0px 4px 3px #eeeeee;
  border-radius: 12px;
  margin-bottom: 4px;
  height: 94px;
  border: 1px solid lightgray;
`;

const BadgeWrapper = styled.div`
  background-color: white;
  padding: 10px;
  // height: 100px;
  border-radius: 14px;
  box-shadow: 1px 1px 4px 3px #eeeeee;
  border: 1px solid #eeeeee;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
`;

const BadgeImage = styled.img`
  height: 70px;
  width: 70px;
  max-height: 70px;
  max-width: 70px;
  border-radius: 25px;
  object-fit: contain;
`;

const RightSideWrapper = styled.div`
  flex: 1;
  padding-left: 16px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const ShowBadge = ({
  badge,
  // nextLevels
}) => {
  // const nextLevel = nextLevels[badge.achievement_code];

  return (
    <Wrapper>
      <BadgeWrapper>
        <BadgeImage src={addLogoString(badge.achievement_level_image)} />
      </BadgeWrapper>
      <RightSideWrapper>
        <span style={{ fontSize: 18, fontWeight: 600 }}>
          {badge.achievement_name}
        </span>
        <div style={{ height: 2 }} />
        <div
          style={{
            display: "flex",
            // background: "#eeeeee90",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            border: "1px solid #eeeeee",
            borderRadius: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // paddingLeft: 8,
              padding: "2px 6px",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <div
              style={{
                height: 15,
                width: 15,
                background: "white",
                borderRadius: 4,
                // border: "1px solid #eeeeee",
                background: "darkgreen",
                boxShadow: "0px 0px 4px 1px seagreen",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 4,
              }}
            >
              <Check style={{ color: "white" }} height={12} width={12} />
            </div>
            <span style={{ fontWeight: 500, fontSize: 14, color: "seagreen" }}>
              Level {badge.achievement_level_level}
            </span>
            {/* <span>:</span> */}
            <ArrowRight height={16} width={16} color="lightgray" />
            <span
              style={{
                color: "slategrey",
                fontWeight: 500,

                fontSize: 13,
              }}
            >
              {" "}
              {badge.achievement_level_descriptions.short_description.english}
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: "100%",
            marginTop: 6,
            flexDirection: "column",
          }}
        >
          {/* <span
            style={{
              fontWeight: 400,
              fontSize: 13,
              lineHeight: 1.2,
              color: "black",
            }}
          >
            Level {badge.achievement_level_level} acheived (
            {badge.achievement_level_descriptions.short_description.english})
          </span> */}

          {/* {nextLevel ? (
            <div
              style={{
                borderRadius: 4,
                marginTop: 4,
              }}
            >
              <span
                style={{ fontSize: 12, fontWeight: 400, color: "darkgrey" }}
              >
                Level {nextLevel.level}:{" "}
                {nextLevel.descriptions.short_description.english}
              </span>
            </div>
          ) : (
            <div />
          )} */}
        </div>
      </RightSideWrapper>
    </Wrapper>
  );
};

export default ShowBadge;

ShowBadge.propTypes = {
  badge: PropTypes.object.isRequired,
  nextLevels: PropTypes.object.isRequired,
};
