import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import API from "../../axios/API";
import { getToken } from "../../helpers";

export const GlobalState = createContext();

const GlobalStateProvider = ({ children }) => {
  const [themeLight, setThemeLight] = useState("light");
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showEditInfoModal, setShowEditInfoModal] = useState(false);
  const [showPushAppstoreModal, setShowPushAppstoreModal] = useState(false);
  const [newBadgeData, setNewBadgeData] = useState(null);
  const [triggerCheckBadge, setTriggerSetBadge] = useState(false);
  const [reviewSubmissionInfo, setReviewSubmissionInfo] = useState(null);

  const token = getToken();

  const getBadgeData = async () => {
    try {
      const { data: newData } = await API.get("/achievements/new-badges/");
      if (newData && newData.new_badges.length > 0) {
        setNewBadgeData(newData);
      }
    } catch (error) {
      setNewBadgeData(null);
    }
  };

  const triggerBadgeDataUpdate = () => setTriggerSetBadge((p) => !p);

  useEffect(() => {
    setShowPushAppstoreModal(isMobile);
  }, []);

  useEffect(() => {
    if (token) {
      getBadgeData();
    }
  }, [triggerCheckBadge, token]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <GlobalState.Provider
      value={{
        themeLight,
        setThemeLight,

        showRegisterModal,
        setShowRegisterModal,
        showUploadModal,
        setShowUploadModal,
        showEditInfoModal,
        setShowEditInfoModal,
        showPushAppstoreModal,
        setShowPushAppstoreModal,
        newBadgeData,
        setNewBadgeData,
        triggerBadgeDataUpdate,
        reviewSubmissionInfo,
        setReviewSubmissionInfo,
      }}
    >
      {children}
    </GlobalState.Provider>
  );
};

export default GlobalStateProvider;

GlobalStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
