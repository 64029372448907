import React, { useEffect, useState } from "react";
import PublicFormLayout from "../../layouts/PublicFormLayout";
import {
  Paper,
  Button,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { User, Lock, Tag } from "react-feather";
import { CustomTextField, CustomSelectField } from "../../styles/forms";
import { useLocation } from "react-router";
import API from "../../axios/API";
import useSnack from "../../store/snack";
import useAuth from "../../store/auth";
import { setToken } from "../../helpers";

const POSSIBLE_SIGNIN_ERRORS = {
  invalid_password: "The password given for this account was incorrect",
  inactive_account:
    "The account you are trying to sign into has been flagged inactive",
  user_not_found: "No account found with this email address",
};

const getSigninFailMessage = (e) => {
  try {
    const { error: parsedErrorMsg } = e?.response?.data || {};
    const msg = POSSIBLE_SIGNIN_ERRORS[parsedErrorMsg];
    return msg || "Failed to login";
  } catch (error) {
    return "Failed to login";
  }
};

const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: flex-start;
`;

const Wrapper = styled(Paper)`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 20px;
  position: relative;

  @media (max-width: 420px) {
    width: 96vw;
    padding: 10px;
    min-width: 300px;
  }
`;

const InitialValues = {
  email: "",
  password: "",
  roles: [],
};

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  roles: Yup.array()
    .required("Role is required")
    .min(1, "At least 1 role must be selected"),
});

const getIsDisabled = (values, errors) => {
  const hasErrors = Object.keys(errors).length >= 1;
  const anyValuesMissingEntries =
    values.email.length === 0 ||
    values.password.length === 0 ||
    values.roles.length === 0;

  return hasErrors || anyValuesMissingEntries;
};

const LoginAndJoinTeam = () => {
  const location = useLocation();
  const [availableRoles, setAvailableRoles] = useState([]);
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;
  const { login } = useAuth();

  useEffect(() => {
    getAvailableRoles(location?.state?.selectedCompany?.id);
  }, [location?.state]);

  const getAvailableRoles = async (id) => {
    try {
      const { data } = await API.get(
        `dashboard/signup/company/roles/?company_id=${id}`,
      );
      setAvailableRoles(data.roles.map((r) => ({ id: r[0], name: r[1] })));
    } catch (error) {
      setSnack("Failed to fetch roles", SNACK_TYPES.ERROR);
    }
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    let token = null;

    // first sign in
    try {
      const { data } = await API.post("/token-auth/", {
        username: values.email,
        password: values.password,
        version: 2,
      });
      token = data.token;
      setToken(token);
    } catch (error) {
      const msg = getSigninFailMessage(error);
      setSnack(msg, SNACK_TYPES.ERROR);
      return setSubmitting(false);
    }

    // then join team
    try {
      await API.post("/teams/search/add/", {
        company_id: location?.state?.selectedCompany?.id,
        location_id: location?.state?.selectedLocation?.id,
        roles: values.roles,
      });

      setSnack(
        `Logged In & Joined Team ${location?.state?.selectedCompany?.name}`,
        SNACK_TYPES.SUCCESS,
      );

      return login(token);
    } catch (error) {
      setSnack(
        error?.response?.data?.error?.details ||
          "Failed to join team and log in",
        SNACK_TYPES.ERROR,
      );
    }
  };

  return (
    <PublicFormLayout>
      <Wrapper>
        <Formik
          initialValues={InitialValues}
          validationSchema={ValidationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            errors,
            values,
            touched,

            handleChange,
            handleBlur,
            isSubmitting,
            handleSubmit,
          }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
              style={{ display: "flex" }}
            >
              <Typography variant="h2">Log In</Typography>
              <CustomTextField
                type="email"
                name="email"
                label="Email"
                onBlur={handleBlur}
                value={values.email}
                onChange={handleChange}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
                variant="outlined"
                color="secondary"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <User />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mb: 4,
                  mt: 8,
                }}
              />
              <CustomTextField
                label="Password"
                error={Boolean(touched.password && errors.password)}
                type="password"
                name="password"
                variant="outlined"
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.password && errors.password}
                fullWidth
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mb: 2,
                }}
                value={values.password}
              />
              <div
                style={{
                  width: "100%",
                }}
              >
                <div style={{ height: 8 }} />
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    color: "black",
                    textAlign: "center",
                    marginLeft: 4,
                  }}
                >
                  Team Information
                </span>
                <div style={{ height: 12 }} />

                <CustomTextField
                  disabled
                  fullWidth
                  label="Team"
                  value={location?.state?.selectedCompany?.name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock color="darkgrey" />
                      </InputAdornment>
                    ),
                  }}
                />
                <div style={{ height: 14 }} />
                <CustomTextField
                  disabled
                  fullWidth
                  label="Location"
                  value={location?.state?.selectedLocation?.name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock color="darkgrey" />
                      </InputAdornment>
                    ),
                  }}
                />

                <div style={{ height: 14 }} />
                <FormControl
                  helperText="error here"
                  onBlur={handleBlur}
                  style={{ width: "100%" }}
                  size="small"
                  disabled={availableRoles.length === 0}
                  error={Boolean(touched.roles && errors.roles)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tag style={{ color: false ? "darkgrey" : "black" }} />
                      </InputAdornment>
                    ),
                  }}
                >
                  <InputLabel id="role-at-company-2">Role On Team</InputLabel>
                  <CustomSelectField
                    multiple
                    label="Role On Team"
                    labelId="role-at-company-2"
                    name="roles"
                    variant="outlined"
                    value={values.roles}
                    color="secondary"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    startAdornment={
                      <InputAdornment position="start">
                        <Tag
                          style={{
                            color:
                              availableRoles.length === 0
                                ? "darkgrey"
                                : "black",
                          }}
                        />
                      </InputAdornment>
                    }
                  >
                    {availableRoles?.map((role) => (
                      <MenuItem key={role.name} value={role.id}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </CustomSelectField>
                  {touched?.roles && errors?.roles && (
                    <FormHelperText id="my-helper-text">
                      <span style={{ fontSize: 11 }}>{errors?.roles}</span>
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div style={{ height: 16 }} />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={getIsDisabled(values, errors) || isSubmitting}
                color="primary"
                size="small"
                startIcon={
                  isSubmitting && (
                    <CircularProgress size={14} sx={{ color: "slategrey" }} />
                  )
                }
                style={{ marginTop: "auto" }}
              >
                Log In & Join Team
              </Button>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </PublicFormLayout>
  );
};
export default LoginAndJoinTeam;
