import React from "react";
import ModuleCardV3 from "./ModuleCardV3";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import LeftButton from "./LeftButton";
import RightButton from "./RightButton";
import DisplayText from "./DisplayText";
import { FileText } from "react-feather";
import useWindowSize from "../../../../hooks/useWindowSize";
import "react-horizontal-scrolling-menu/dist/styles.css";

const DisplayModulesV3 = ({
  modules,
  navigate,
  getNextModuleInfo,
  companyName,
}) => {
  const { width } = useWindowSize();

  return (
    <ScrollMenu
      Header={
        <div
          style={{
            padding: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 8,
          }}
        >
          <FileText
            color="black"
            height={20}
            width={20}
            style={{ padding: 3, borderRadius: 10, background: "#eeeeee" }}
          />
          <span style={{ fontSize: 14, fontWeight: "500", color: "darkgrey" }}>
            Modules from {companyName}
          </span>
        </div>
      }
      Footer={
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: 14,
            alignItems: "",
            justifyContent: "space-between",
          }}
        >
          <LeftButton />
          {width > 500 && <DisplayText numModules={modules.length} />}
          <RightButton />
        </div>
      }
    >
      {modules.map((item) => (
        <ModuleCardV3
          key={item.id}
          itemId={item.id}
          mod={item}
          navigate={navigate}
          getNextModuleInfo={getNextModuleInfo}
        />
      ))}
    </ScrollMenu>
  );
};

export default DisplayModulesV3;
