import React from "react";
import { Paper, Typography, InputAdornment, Button } from "@mui/material";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Mail } from "react-feather";
import { CustomTextField } from "../../styles/forms";
import API from "../../axios/API";
import useSnack from "../../store/snack";
import PublicFormLayout from "../../layouts/PublicFormLayout";

const FormContainer = styled(Paper)`
  min-width: 420px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    width: 96%;
    height: 340px;
  }
`;

const RegisterSpan = styled.span`
  font-size: 11px;
  color: ${(props) => props.theme.palette.background.bw};
  margin-top: 0px;

  a {
    color: ${(props) => props.theme.palette.info.dark};
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const StyledButton = styled(Button)`
  border: 1px solid black;
  color: black;
`;

const InitialValues = {
  email: "",
};

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
});

const ForgotPassword = () => {
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      await API.post("/v2/recover-password/", {
        email: values.email,
      });
      setSnack(
        "Password reset email sent. Please check your inbox.",
        SNACK_TYPES.SUCCESS,
      );
      return setSubmitting(false);
    } catch (error) {
      setSnack(
        "Did not sent reset password. Please try again.",
        SNACK_TYPES.ERROR,
      );
      return setSubmitting(false);
    }
  };

  return (
    <PublicFormLayout>
      <FormContainer>
        <Typography variant="h2" sx={{ mt: 2 }} align="left">
          Reset Password
        </Typography>
        <Typography variant="body1">
          Enter your email to reset password
        </Typography>
        <Formik
          initialValues={InitialValues}
          validationSchema={ValidationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          }) => (
            <>
              <form noValidate onSubmit={handleSubmit}>
                <CustomTextField
                  label="Email"
                  type="email"
                  name="email"
                  variant="outlined"
                  color="secondary"
                  value={values.email}
                  onBlur={handleBlur}
                  error={Boolean(touched.email && errors.email)}
                  onChange={handleChange}
                  helperText={touched.email && errors.email}
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Mail />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    mt: 8,
                    mb: 4,
                  }}
                />
                <StyledButton
                  type="submit"
                  fullWidth
                  variant="outlined"
                  sx={{ p: 1, fontSize: 14 }}
                  disabled={!isValid || isSubmitting}
                >
                  Submit
                </StyledButton>
              </form>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 8,
                }}
              >
                <RegisterSpan>
                  Go back to <Link to="/signin/">Log In</Link>
                </RegisterSpan>
              </div>
            </>
          )}
        </Formik>
      </FormContainer>
    </PublicFormLayout>
  );
};

export default ForgotPassword;
