import React from "react";
import { InputAdornment } from "@mui/material";
import { Lock } from "react-feather";
import { CustomTextField } from "../../../styles/forms";

const PasswordField = ({
  values,
  touched,
  handleChange,
  errors,
  handleBlur,
}) => (
  <CustomTextField
    label="Password"
    type="password"
    name="password"
    variant="outlined"
    value={values.password}
    color="secondary"
    onBlur={handleBlur}
    error={Boolean(touched.password && errors.password)}
    onChange={handleChange}
    helperText={touched.password && errors.password}
    fullWidth
    size="small"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Lock />
        </InputAdornment>
      ),
    }}
    sx={{
      mb: 4,
    }}
  />
);

export default PasswordField;
