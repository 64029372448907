import { Button, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import API from "../../../axios/API";
import LocationOption from "./LocationOption";
import {
  ButtonHolder,
  Centered,
  ShowSearchResults,
  TitleWrapper,
} from "./styles";

const Wrapper = styled.div`
  padding: 6px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SelectLocation = ({ state, dispatch, ACTIONS }) => {
  const [locations, setLocations] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [selected, setSelected] = useState(null);

  const { selectedCompany } = state;

  useEffect(() => {
    const getLocations = async () => {
      setFetching(true);
      try {
        const { data } = await API.get(
          `/teams/search/locations/?company_id=${selectedCompany.id}`,
        );
        setLocations(data.locations);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };

    getLocations();
  }, []);

  return (
    <Wrapper>
      <TitleWrapper>
        <span style={{ fontSize: 18, fontWeight: "600" }}>
          Select A Location
        </span>
      </TitleWrapper>
      <ShowSearchResults>
        {fetching ? (
          <Centered>
            <CircularProgress size={20} color="secondary" />
          </Centered>
        ) : (
          locations.map((loc) => (
            <LocationOption
              key={loc.id}
              loc={loc}
              selected={selected}
              setSelected={setSelected}
            />
          ))
        )}
      </ShowSearchResults>
      <ButtonHolder>
        <Button
          onClick={() =>
            dispatch({
              type: ACTIONS.REMOVE_SELECTED_COMPANY,
            })
          }
          color="secondary"
          variant="outlined"
          size="small"
        >
          Go Back
        </Button>
        <Button
          onClick={() => {
            if (selected) {
              return dispatch({
                type: ACTIONS.ADD_SELECTED_LOCATION,
                payload: selected,
              });
            }
            return dispatch({ type: ACTIONS.SET_INDEX, payload: 2 });
          }}
          color="primary"
          variant="contained"
          size="small"
          disabled={!state.selectedLocation && !selected}
          sx={{ ml: 1 }}
        >
          Next Step
        </Button>
      </ButtonHolder>
    </Wrapper>
  );
};

export default SelectLocation;

SelectLocation.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
};
