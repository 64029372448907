import { useQuery } from "react-query";
import API from "../../axios/API";

const getUserData = async () => {
  const { data } = await API.get("/users/");
  return data;
};

const useUserData = () => useQuery("users", getUserData);

export default useUserData;
