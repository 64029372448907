import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LinearProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { AlertTriangle, Calendar, ChevronDown } from "react-feather";
import styled from "styled-components/macro";
import GREENCHECK from "../../../../assets/check.png";
import REDX from "../../../../assets/redx.png";
import useCompletionDetail from "../../../../reactQueryHooks/review/useCompletionDetail";
import CompletionMedia from "./CompletionMedia";
import CompletionResults from "./CompletionResults";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 440px;
  padding: 20px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.background.main};
  max-height: 90vh;
  overflow-y: scroll;
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  background: white;
  border-radius: 6px;
  padding: 0px 10px;
  margin-bottom: 4px;

  &:before {
    background-color: transparent;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0px;
  border: none;
  background: transparent;
`;

const ModuleImage = styled.img`
  height: 200px;
  width: 200px;
  object-fit: contain;
  background-color: white;
  border-radius: 8px;
  border: 1px solid lightgray;

  @media (max-width: 500px) {
    height: 100px;
    width: 100px;
  }
`;

const DetailsContainer = styled.div`
  border-radius: 4px;
  width: 100%;
  min-height: 401px;
  display: flex;
  align-items: flex-start;

  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 8px;

  @media (max-width: 600px) {
    flex-direction: column;
    height: auto;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }
`;

const ModuleInfoWrapper = styled.div`
  flex-grow: 1;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
  min-height: 100%;
  border-radius: 4px;
  padding: 16px;
  flex-grow: 1;
  width: 100%;
  margin-left: 20px;
  background: #eeeeee;

  gap: 4px;

  @media (max-width: 600px) {
    margin-left: 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const Subtitle = styled.span`
  font-size: 12px;
  color: slategrey;
  text-align: left;
  font-weight: 500;
`;

const getScoreAchieved = (questions, answers) => {
  let correct = 0;
  const correctIndexes = [];
  const cleanAnswers = answers.map((a) => JSON.parse(a));
  try {
    questions.forEach((question, index) => {
      if (question.type === "tf") {
        if (
          question.correctAnswer.toLowerCase() ===
          cleanAnswers[index].toLowerCase()
        ) {
          correct += 1;
          correctIndexes.push(index);
        }
      }

      if (question.type === "mc") {
        if (
          question.correctAnswer.toLowerCase() ===
          cleanAnswers[index].toLowerCase()
        ) {
          correct += 1;
          correctIndexes.push(index);
        }
      }

      if (question.type === "sa") {
        if (
          question.correctAnswer.length === cleanAnswers[index].length &&
          question.correctAnswer.every((val) =>
            cleanAnswers[index].includes(val.toLowerCase()),
          )
        ) {
          correct += 1;
          correctIndexes.push(index);
        }
      }
    });

    return {
      score: correct,
      correctIndexes,
    };
  } catch (error) {
    return {
      score: correct,
      correctIndexes: [],
    };
  }
};

const CompletionDetails = ({ reviewInfo }) => {
  const { id, logo } = reviewInfo || {};

  const [expandedIndex, setExpandedIndex] = useState(0);

  const { isLoading, error, data } = useCompletionDetail(id);

  if (!id) return null;

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          flex: 1,
          minHeight: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <span style={{ color: "darkgrey" }}>loading submission...</span>
        <LinearProgress
          variant="indeterminate"
          style={{ width: 160, borderRadius: 2 }}
          color="secondary"
        />
      </div>
    );
  }

  if (error)
    return (
      <div
        style={{
          width: "100%",
          flex: 1,
          minHeight: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <AlertTriangle color="firebrick" />
        <span style={{ color: "black", fontSize: 14, fontWeight: "500" }}>
          Error loading this submission
        </span>
      </div>
    );

  const parsedQuestions = JSON.parse(data.quiz__questions);
  const numQuestions = parsedQuestions.questions.length;
  const { score, correctIndexes } = getScoreAchieved(
    parsedQuestions.questions,
    data.user_answers,
  );

  return (
    <Wrapper>
      <div
        style={{
          background: "white",
          padding: "2px 6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          justifySelf: "flex-start",
          width: "fit-content",
          borderRadius: 3,
          gap: 4,
        }}
      >
        <Calendar height={12} width={12} color="slategrey" />
        <span style={{ fontSize: 12, color: "black", fontWeight: "500" }}>
          {new Date(data.created).toDateString()}
        </span>
      </div>
      <div style={{ height: 12 }} />
      <StyledAccordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={expandedIndex === 0}
        style={{
          border:
            expandedIndex === 0
              ? "1px solid lightgray"
              : "1px solid transparent",
        }}
        onChange={() => {
          if (expandedIndex === 0) {
            return setExpandedIndex(null);
          }
          return setExpandedIndex(0);
        }}
      >
        <StyledAccordionSummary expandIcon={<ChevronDown />}>
          <span
            style={{
              fontSize: 14,
              fontWeight: "500",
              color: expandedIndex === 0 ? "black" : "slategrey",
            }}
          >
            Module Info
          </span>
        </StyledAccordionSummary>
        <AccordionDetails style={{ overflowY: "scroll", maxHeight: 440 }}>
          <DetailsContainer>
            <ModuleImage src={logo} alt="module" />
            <ModuleInfoWrapper>
              <span
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  lineHeight: 1.2,
                }}
              >
                {data.quiz__name}
              </span>
              <div style={{ height: 12 }} />
              <Subtitle>Module Created By</Subtitle>
              <span style={{ fontSize: 14, fontWeight: "500" }}>
                {data?.quiz__company__name}
              </span>
              <div style={{ height: 8 }} />
              <Subtitle>Description</Subtitle>
              <span style={{ fontSize: 14, fontWeight: "500" }}>
                {data.quiz__description}
              </span>
              <div style={{ height: 8 }} />
              <Subtitle>Score To Pass</Subtitle>
              <span style={{ fontSize: 14, fontWeight: "500" }}>
                {data.quiz__pass_score}/{numQuestions}
              </span>
              <div style={{ height: 8 }} />
              <Subtitle>Result</Subtitle>
              <div style={{ height: 4 }} />

              <div style={{ display: "flex", alignItems: "center" }}>
                {[...Array(numQuestions).keys()].map((q, i) => (
                  <div
                    key={q.id}
                    style={{
                      marginRight: 6,
                      background: "white",
                      position: "relative",
                      border: "1px solid slategrey",
                      borderRadius: 6,
                      height: 30,
                      width: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ fontSize: 14, fontWeight: "600" }}>
                      {i + 1}
                    </span>
                    <img
                      style={{
                        position: "absolute",
                        top: -4,
                        right: -4,
                        height: 12,
                        width: 12,
                        borderRadius: 6,
                        objectFit: "contain",
                      }}
                      src={correctIndexes.includes(i) ? GREENCHECK : REDX}
                      alt="check or x"
                    />
                  </div>
                ))}
              </div>
              <div style={{ height: 8 }} />
              <span
                style={{ color: "darkgreen", fontSize: 14, fontWeight: 500 }}
              >
                <b style={{ fontWeight: 600 }}>Passed</b> module with score{" "}
                <b style={{ fontWeight: 600 }}>
                  {score}/{numQuestions}
                </b>
              </span>
            </ModuleInfoWrapper>
          </DetailsContainer>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={expandedIndex === 1}
        style={{
          border:
            expandedIndex === 1
              ? "1px solid lightgray"
              : "1px solid transparent",
        }}
        onChange={() => {
          if (expandedIndex === 1) {
            return setExpandedIndex(null);
          }
          return setExpandedIndex(1);
        }}
      >
        <StyledAccordionSummary expandIcon={<ChevronDown />}>
          <span
            style={{
              fontSize: 14,
              fontWeight: "500",
              color: expandedIndex === 1 ? "black" : "slategrey",
            }}
          >
            Module Content
          </span>
        </StyledAccordionSummary>
        <AccordionDetails
          style={{
            overflowY: "scroll",
            maxHeight: 440,
          }}
        >
          <CompletionMedia data={data} />
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={expandedIndex === 2}
        style={{
          border:
            expandedIndex === 2
              ? "1px solid lightgray"
              : "1px solid transparent",
        }}
        onChange={() => {
          if (expandedIndex === 2) {
            return setExpandedIndex(null);
          }
          return setExpandedIndex(2);
        }}
      >
        <StyledAccordionSummary expandIcon={<ChevronDown />}>
          <span
            style={{
              fontSize: 14,
              fontWeight: "500",
              color: expandedIndex === 2 ? "black" : "slategrey",
            }}
          >
            Your Answers
          </span>
        </StyledAccordionSummary>
        <AccordionDetails style={{ overflowY: "scroll", maxHeight: 400 }}>
          <CompletionResults data={data} />
        </AccordionDetails>
      </StyledAccordion>
    </Wrapper>
  );
};

export default CompletionDetails;

CompletionDetails.propTypes = {
  reviewInfo: PropTypes.object,
};

CompletionDetails.defaultProps = {
  reviewInfo: null,
};
