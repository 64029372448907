import React from "react";
import { InputAdornment } from "@mui/material";
import { User } from "react-feather";
import { CustomTextField } from "../../../styles/forms";

const LastNameField = ({
  values,
  touched,
  handleChange,
  errors,
  handleBlur,
}) => {
  return (
    <CustomTextField
      label="Last Name"
      type="text"
      name="lastName"
      variant="outlined"
      color="secondary"
      value={values.lastName}
      onBlur={handleBlur}
      error={Boolean(touched.lastName && errors.lastName)}
      onChange={handleChange}
      helperText={touched.lastName && errors.lastName}
      fullWidth
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <User />
          </InputAdornment>
        ),
      }}
      sx={{
        mb: 4,
      }}
    />
  );
};

export default LastNameField;
