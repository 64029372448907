import React, { useState } from "react";
import PropTypes from "prop-types";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import { ChevronRight, X, ChevronLeft } from "react-feather";
import {
  IconButton,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import styled from "styled-components/macro";
import useWindowSize from "../../hooks/useWindowSize";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ModalWrapper = styled.div`
  position: relative;
  height: 100%;
  background: lightgray;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: center;
`;

const RelativeIconButton = styled(IconButton)`
  position: fixed;
  top: 18px;
  right: 18px;
  z-index: 100000;
  cursor: pointer;
  border-radius: 6px;
  background: #eeeeee;

  svg {
    height: 20px;
    width: 20px;
    color: black;
  }

  &:hover {
    background: #eeeeee80;
  }
`;

const FloatingPageDiv = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  background-color: ${(props) => props.theme.palette.background.paper};
  width: 180px;
  height: 40px;
  transform: translateX(-50%);
  display: flex;
  padding: 2px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  opacity: ${(props) => (props.$show ? 1 : 0)};
  pointer-events: ${(props) => (props.$show ? "auto" : "none")};
  transition: opacity 200ms ease-in;
`;

const StyledDocument = styled(Document)`
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
`;

const SmallButton = styled(Button)`
  width: 30px;
`;

const Center = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.background.paper};
`;

const NumPageSpan = styled.span`
  color: ${(props) => props.theme.palette.background.bw};
  font-size: 12px;
`;

const ShowPage = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
`;

const SizeButton = styled(Button)`
  background: white;
  color: darkgrey;
  padding: 0px 4px;

  font-size: 12px;
`;

// Come back to this or delete
const LoadingThePdf = () => (
  <Center>
    <CircularProgress color="primary" size={30} />
  </Center>
);

const PdfError = () => (
  <Center>
    <Typography variant="subtitle1" color="warning">
      Error Loading Pdf
    </Typography>
  </Center>
);

const SIZES = {
  FULL_SCREEN: "FULL_SCREEN",
  FULL_HEIGHT: "FULL_HEIGHT",
};

const ToggleSizeComponent = ({ size, setSize }) => (
  <div
    style={{
      padding: 4,
      borderRadius: 4,
      background: "#eeeeee",
      gap: 6,
      zIndex: 10000,
      position: "fixed",
      top: 18,
      left: 18,
      display: "flex",
    }}
  >
    <SizeButton
      style={{
        border:
          size === SIZES.FULL_HEIGHT
            ? "1px solid #337ab7"
            : "1px solid lightgray",
        color: size === SIZES.FULL_HEIGHT ? "#337ab7" : "darkgrey",
      }}
      size="small"
      onClick={() => setSize(SIZES.FULL_HEIGHT)}
    >
      Full Height
    </SizeButton>
    <SizeButton
      style={{
        border:
          size === SIZES.FULL_SCREEN
            ? "1px solid #337ab7"
            : "1px solid lightgray",
        color: size === SIZES.FULL_SCREEN ? "#337ab7" : "darkgrey",
      }}
      size="small"
      onClick={() => setSize(SIZES.FULL_SCREEN)}
    >
      Full Screen
    </SizeButton>
  </div>
);

const ShowPdfFullScreen = ({ setIsFullSize, SRC }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isHovering, setIsHovering] = useState(false);
  const { width, height } = useWindowSize();
  const [size, setSize] = useState(SIZES.FULL_SCREEN);

  const onDocumentLoadSuccess = ({ numPages: numberPages }) => {
    setNumPages(numberPages);
  };

  const PDF_WIDTH = width - 28;

  return (
    <ModalWrapper
      onMouseLeave={() => setIsHovering(false)}
      onMouseOver={() => setIsHovering(true)}
    >
      <StyledDocument
        onLoadSuccess={onDocumentLoadSuccess}
        file={SRC}
        loading={<LoadingThePdf />}
        error={<PdfError />}
      >
        <Page
          pageNumber={pageNumber}
          height={size === SIZES.FULL_HEIGHT ? height - 20 : undefined}
          width={size === SIZES.FULL_SCREEN ? PDF_WIDTH : undefined}
        />
        <FloatingPageDiv $show={isHovering}>
          <SmallButton
            color="primary"
            startIcon={<ChevronLeft />}
            disabled={pageNumber === 1}
            onClick={() => setPageNumber((p) => p - 1)}
          />
          <ShowPage>
            <NumPageSpan>Page</NumPageSpan>
            <NumPageSpan>
              <b>{pageNumber}</b> of <b>{numPages}</b>
            </NumPageSpan>
          </ShowPage>
          <SmallButton
            disabled={pageNumber === numPages}
            startIcon={<ChevronRight />}
            onClick={() => setPageNumber((p) => p + 1)}
          />
        </FloatingPageDiv>
      </StyledDocument>
      <RelativeIconButton
        size="small"
        onClick={(e) => {
          setIsFullSize(false);
        }}
      >
        <X />
      </RelativeIconButton>
      <ToggleSizeComponent size={size} setSize={setSize} />
    </ModalWrapper>
  );
};

export default ShowPdfFullScreen;

ShowPdfFullScreen.propTypes = {
  setIsFullSize: PropTypes.func.isRequired,
  SRC: PropTypes.string.isRequired,
};
