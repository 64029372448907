import axios from "axios";
import { BASE_URL } from "../../constants/secret";
import { getToken, removeToken } from "../../helpers";

const instance = axios.create({
  baseURL: BASE_URL,
});

// Adds the token to requests if there is a token in storage
instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

instance.interceptors.response.use(
  (config) => {
    return config;
  },
  (err) => {
    const { data, status } = err.response;
    if (status === 401 && data.code && data.code === "token_not_valid") {
      removeToken();
      window.location = "/signin";
    }
    return Promise.reject(err);
  },
);

export default instance;
