import React from "react";
import styled from "styled-components/macro";
import { MODULE_TYPES } from "../../../../constants/common";

const Wrapper = styled.div`
  span {
    font-size: 14px;
    color: darkgrey;
  }
`;

const renderCompanyText = (companyFilter) => {
  if (companyFilter?.id === 0) return null;

  return (
    <>
      From <b style={{ color: "slategrey" }}>{companyFilter?.name}</b>
    </>
  );
};

const renderModuleTypeText = (showModules) => {
  if (showModules === MODULE_TYPES.ALL) return "Modules";
  if (showModules === MODULE_TYPES.ASSIGNED)
    return (
      <span style={{ color: "rgb(88, 125, 234)", fontWeight: "500" }}>
        Assigned Modules
      </span>
    );
  if (showModules === MODULE_TYPES.EXTRA_CREDIT)
    return (
      <span style={{ fontWeight: "500", color: "rgb(170, 151, 0)" }}>
        Extra Credit Modules
      </span>
    );
};

const FeedEmpty = ({ showModules, companyFilter }) => {
  return (
    <Wrapper>
      <span>
        No {renderModuleTypeText(showModules)} In Your Feed{" "}
        {renderCompanyText(companyFilter)}
      </span>
    </Wrapper>
  );
};

export default FeedEmpty;
