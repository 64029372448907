import { useQuery } from "react-query";
import API from "../../axios/API";

const getCompletionnDetail = async (submissionId) => {
  const { data } = await API.get(`submissions/detail/?id=${submissionId}`);
  return data;
};

const useCompletionDetail = (submissionId) =>
  useQuery(["submissionDetail", submissionId], () =>
    submissionId ? getCompletionnDetail(submissionId) : null,
  );

export default useCompletionDetail;
