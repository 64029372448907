const TOKEN_KEY = "spflearningtokenxyxyxygoleafsgo";

export const setToken = (token) => localStorage.setItem(TOKEN_KEY, token);
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const removeToken = () => localStorage.removeItem(TOKEN_KEY);

export const addLogoString = (logoStr) =>
  `${process.env.REACT_APP_COMPLETIONS_IMAGE_URL}${logoStr}`;

const getAllModulesFlat = (arr) => {
  let allMods = [];
  try {
    arr.forEach((obj) => {
      let streamMods = [];

      obj.streams?.map((stream) => {
        stream.quizzes.forEach((mod) =>
          streamMods.push({ ...mod, company: obj.name }),
        );
      });

      const regularModsFlat = obj.quizzes.map((quiz) => ({
        ...quiz,
        company: obj.name,
      }));

      allMods.push(...streamMods);
      allMods.push(...regularModsFlat);
    });
  } catch (error) {
    console.log(error);
    return [];
  }

  return allMods || [];
};

export const handleModuleResponseV4 = (response) => {
  try {
    let feedArray = [];
    let allModules = [];
    let allTeams = [];
    let allBrands = [];

    const { custom, marketplace, extra_credit: extraCredit } = response || {};

    const labelledCustom = custom?.map((obj) => ({
      ...obj,
      isTeam: true,
      isBrand: false,
      isExtraCredit: false,
    }));

    const labelledMarketplace = marketplace?.map((obj) => ({
      ...obj,
      isBrand: true,
      isTeam: false,
      isExtraCredit: false,
    }));

    const labelledExtraCredit = extraCredit?.map((obj) => ({
      ...obj,
      isExtraCredit: true,
      isBrand: false,
      isTeam: false,
    }));

    /// Assign

    allTeams = labelledCustom?.map((obj) => ({
      id: obj.id,
      name: obj.name,
      logo: obj.logo,
      isTeam: true,
      numModules:
        obj?.quizzes?.length +
        obj?.streams
          ?.map((s) => s.quizzes.length)
          .reduce((partialSum, a) => partialSum + a, 0),
    }));

    allBrands = [...labelledMarketplace, ...labelledExtraCredit]?.map(
      (obj) => ({
        id: obj.id,
        name: obj.name,
        logo: obj.logo,
        isTeam: false,
        numModules:
          obj?.quizzes?.length +
          obj?.streams
            ?.map((s) => s.quizzes.length)
            .reduce((partialSum, a) => partialSum + a, 0),
      }),
    );

    allModules = getAllModulesFlat([
      ...labelledCustom,
      ...labelledMarketplace,
      ...labelledExtraCredit,
    ]);

    feedArray = [
      ...labelledCustom,
      ...labelledMarketplace,
      ...labelledExtraCredit,
    ];

    return {
      feedArray,
      allModules,
      allTeams,
      allBrands,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getNumberWithOrdinal = (n) => {
  if (!n) return "N/A";

  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  if (n === 1) {
    return "🥇1st";
  }
  if (n === 2) {
    return "🥈2nd";
  }
  if (n === 3) {
    return "🥉3rd";
  }
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getTimezoneName = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return tz || "America/Toronto";
};
