import { useQuery } from "react-query";
import API from "../../axios/API";

const getCountries = async () => {
  const { data } = await API.get("dashboard/address/countries/");
  return data.countries;
};

const useAllCountries = () => useQuery("countries", getCountries);

export default useAllCountries;
