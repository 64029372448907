/* redeploy */
import React, { useContext, useRef } from "react";
import styled from "styled-components/macro";
import LOGO from "../../assets/main-logo.webp";
import sidebarRoutes from "../../routes/sidebarRoutes";
import { NavLink } from "react-router-dom";
import { Slide } from "@mui/material";
import GetOurApp from "./GetOurApp";
import { GlobalState } from "../../store/global";

const Wrapper = styled.nav`
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 64px;
  padding: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeadImage = styled.img`
  padding: 10px;
  object-fit: contain;
  height: 100%;
  height: 52px;
  width: 120px;
  margin-left: 18px;
`;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 6px;
  flex-grow: 1;
`;

const CustomLink = styled(NavLink)`
  height: 36px;
  width: 94%;
  background: #eeeeee80;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  margin: 4px;
  align-self: center;
  border-radius: 14px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;

  color: slategrey;

  svg {
    transition: 200ms ease-in;
    color: slategrey;
    height: 14px;
    width: 14px;
  }

  span {
    font-size: 15px;
    color: slategrey;
  }

  &.active {
    background-color: white;
    border-radius: 14px;
    box-shadow: 0px 0px 5px 1px gainsboro;

    svg {
      color: black;
    }

    span {
      color: black;
      text-decoration: none;
      font-weight: 600;
    }
  }

  &:hover {
    box-shadow: 0px 0px 5px 1px lightgray;

    svg {
      color: black;
      transform: scale(1.03);
    }
  }
`;

const IconWrapper = styled.div`
  margin-right: 6px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  background: white;
`;

const ShowRoute = ({ route }) => {
  return (
    <CustomLink key={route.name} to={route.url}>
      <IconWrapper>
        <route.icon />
      </IconWrapper>
      <span>{route.name}</span>
    </CustomLink>
  );
};

const NavDrawer = ({ closeDrawer }) => {
  const { themeLight } = useContext(GlobalState);
  const logoRef = useRef(null);

  return (
    <Wrapper>
      <ImageWrapper ref={logoRef}>
        <Slide
          direction="right"
          in={themeLight === "light"}
          mountOnEnter
          unmountOnExit
          timeout={{
            appear: 300,
            enter: 300,
            exit: 150,
          }}
          container={logoRef.current}
        >
          <HeadImage src={LOGO} />
        </Slide>
      </ImageWrapper>
      <LinkContainer>
        {sidebarRoutes.map((route) => (
          <ShowRoute key={route.name} route={route} closeDrawer={closeDrawer} />
        ))}
      </LinkContainer>
      <GetOurApp />
    </Wrapper>
  );
};

export default NavDrawer;
