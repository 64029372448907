import React, { useContext, useRef, useState } from "react";
import { Avatar, Modal, Typography } from "@mui/material";
import styled from "styled-components/macro";
import useUserData from "../../../reactQueryHooks/user/useUserData";
import { GlobalState } from "../../../store/global";
import useSnack from "../../../store/snack";
import EditUserInfoModal from "../../Modals/EditUserInfoModal";
import UploadProfilePhoto from "../../Modals/UploadProfilePhotoModal";
import ReuseablePopper from "../../Reuseable/ReuseablePopper";
import TeamsDropdown from "../TeamsDropdown";
import ProfileOpen from "./ProfileOpen";

const NameText = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[400]};

  @media (max-width: 600px) {
    display: none;
  }
`;

const CustomAvatar = styled(Avatar)`
  height: 100%;
  width: 100%;
  border: 2px solid transparent;
  cursor: pointer;

  &:hover {
    border: 2px solid #337ab7;
  }
`;

const AvatarWrapper = styled.div`
  margin-left: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  padding: 2px;
  border-radius: 50%;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const UserProfile = () => {
  const [anchorEl, setAnchorEl] = useState(false);
  const {
    showUploadModal,
    setShowUploadModal,
    showEditInfoModal,
    setShowEditInfoModal,
  } = useContext(GlobalState);
  const { isLoading, error, data, refetch } = useUserData();

  const containerRef = useRef(null);
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;

  if (isLoading) return null;

  if (error) return null;

  const { profile } = data;

  const allInfoUpToDate =
    profile.first_name &&
    profile.last_name &&
    profile.email &&
    profile.country &&
    profile.state &&
    profile.birth_date;

  const { first_name, last_name, photo } = profile;

  const initials = `${first_name.trim()[0]}${last_name.trim()[0]}`;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleExitModal = () => {
    if (allInfoUpToDate) {
      setShowEditInfoModal(false);
    } else {
      setSnack(
        "Must fill mandatory information before accessing app",
        SNACK_TYPES.WARNING,
      );
    }
  };

  return (
    <>
      <TeamsDropdown />
      <div
        style={{ height: 34, marginRight: 12, width: 1, background: "#eeeeee" }}
      />
      <NameText
        variant="h6"
        sx={{
          mr: 1,
        }}
      >
        {`${first_name} ${last_name}`}
      </NameText>
      <AvatarWrapper onClick={handleClick} ref={containerRef}>
        <CustomAvatar size="small" src={photo} />
      </AvatarWrapper>

      <ReuseablePopper anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <ProfileOpen data={data} />
      </ReuseablePopper>
      <Modal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        aria-labelledby="modal-edit-profile-picture"
        aria-describedby="modal-edit-your-profile-picture"
      >
        <div>
          <UploadProfilePhoto setShowUploadModal={setShowUploadModal} />
        </div>
      </Modal>
      <Modal
        open={showEditInfoModal}
        onClose={handleExitModal}
        aria-labelledby="modal-edit-info"
        aria-describedby="modal-edit-personal-information"
      >
        <div>
          <EditUserInfoModal
            profile={profile}
            refetch={refetch}
            handleExitModal={handleExitModal}
          />
        </div>
      </Modal>
    </>
  );
};

export default UserProfile;
