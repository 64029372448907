import { Button, Collapse, Modal, Popover, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { AlertTriangle, CheckCircle, Edit, HelpCircle } from "react-feather";
import { useQueryClient } from "react-query";
import styled from "styled-components/macro";
import API from "../../../axios/API";
import AddTeamModal from "../../../components/Modals/AddTeamModal";
import ModifyRolesModal from "../../../pages/private/Profile/Teams/ModifyRolesModal";
import ModifyTeamLocationModal from "../../../pages/private/Profile/Teams/ModifyTeamLocationModal";
import useTeams from "../../../reactQueryHooks/teams/useTeams";
import useSnack from "../../../store/snack";
import { colors } from "../../../theme/colors";
import DisplayTeam from "./DisplayTeam";
import DynamicTeamsIcon from "./DynamicTeamsIcon";

const TeamsWrapper = styled(Button)`
  margin-right: 12px;
  height: 38px;
  width: 38px;
  min-width: 38px;
  max-width: 38px;
  max-height: 38px;
  min-height: 38px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px;
  transition: all 140ms ease-in;
  position: relative;
  border: ${(props) =>
    props.$isOpen ? "2px solid #337ab7" : "1px solid #eeeeee"};

  &:hover {
    border: 2px solid #337ab7;

    img {
      transform: scale(0.99);
    }
  }
`;

const TeamsOpenWrapper = styled.div`
  min-height: 120px;
  width: 340px;
  border-radius: 4px;
  // position: absolute;
  z-index: 101;
  // top: 42px;
  background: white;
  // right: 0px;
  box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.2);

  span {
    color: black;
  }

  @media (max-width: 420px) {
    top: 60px;
    right: -60px;
  }
`;

const MoreInfoContainer = styled.div`
  height: 18px;
  width: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    background: #eeeeee;
  }

  svg {
    height: 12px;
    width: 12px;
    color: darkgrey;

    &:hover {
      color: black;
    }
  }
`;

const CustomButton = styled(Button)`
  height: 26px;
  border: 1px solid gainsboro;
  padding: 2px 8px;
  color: slategrey;
  border-radius: 8px;
  background: #eeeeee90;
  font-size: 12px;

  &:hover {
    background-color: white;
  }
`;

const MoreInfo = ({ title, body }) => {
  return (
    <Tooltip
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "white",
            border: "1px solid lightgray",
            "& .MuiTooltip-arrow": {
              color: "darkgrey",
            },
          },
        },
      }}
      sx={{
        background: "white",
      }}
      title={
        <div
          style={{
            width: 220,
            minHeight: 40,
            background: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            lineHeight: 1.3,
          }}
        >
          <span style={{ color: "black", fontSize: 11, fontWeight: "500" }}>
            {title}
          </span>
          <span
            style={{
              lineHeight: 1.2,
              color: "black",
              fontSize: 10,
              fontWeight: "400",
            }}
          >
            {body}
          </span>
        </div>
      }
    >
      <MoreInfoContainer>
        <HelpCircle />
      </MoreInfoContainer>
    </Tooltip>
  );
};

const TeamsBoxHead = () => {
  const [addTeamOpen, setAddTeamOpen] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "2px 0px",
        borderBottom: "1px solid lightgray",
      }}
    >
      <span style={{ fontSize: 14, fontWeight: "600" }}>Teams</span>
      <Tooltip arrow title="Add Team">
        <Button
          size="small"
          variant="text"
          color="secondary"
          sx={{
            p: 0,
            minWidth: 40,
            width: 40,
            maxWidth: 40,
            fontSize: 12,
            height: 24,
          }}
          onClick={() => setAddTeamOpen((p) => !p)}
        >
          + Add
        </Button>
      </Tooltip>
      <Modal
        disableAutoFocus
        open={addTeamOpen}
        onClose={() => setAddTeamOpen(false)}
      >
        <div>
          <AddTeamModal setAddTeamModalOpen={setAddTeamOpen} />
        </div>
      </Modal>
    </div>
  );
};

const TeamsDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [isLeaving, setIsLeaving] = useState(false);
  const [editLocationOpen, setEditLocationOpen] = useState(false);
  const [editRolesOpen, setEditRolesOpen] = useState(false);
  const { data, isLoading, refetch } = useTeams();
  const queryClient = useQueryClient();
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;

  if (isLoading) return null;

  const pendingTeams = data.teams.filter((t) => !t.approved);
  const approvedTeams = data.teams.filter((t) => t.approved);

  const hasApprovedTeams = approvedTeams.length > 0;
  const hasPendingTeams = pendingTeams.length > 0;

  const hasNoTeams = data?.teams.length === 0;

  const handleLeaveTeam = async () => {
    try {
      setIsLeaving(true);
      await API.delete(`/v2/employee-team/${selectedTeamId}/`);

      await refetch();
      await queryClient.invalidateQueries("allModulesV2", {
        refetchInactive: true,
      });
      setSnack(
        `Left team ${data?.teams?.find((t) => t.id === selectedTeamId)?.company__name}`,
        SNACK_TYPES.WARNING,
      );

      setSelectedTeamId(null);
      setIsLeaving(false);
    } catch (error) {
      setIsLeaving(false);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <TeamsWrapper
        isopen={Boolean(anchorEl) ? "Y" : "N"}
        $isOpen={Boolean(anchorEl)}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <DynamicTeamsIcon
          color={hasNoTeams ? "slategrey" : pendingTeams ? "black" : "black"}
        />
        {pendingTeams.length > 0 && (
          <div
            style={{
              height: 14,
              width: 14,
              top: -4,
              right: -4,
              position: "absolute",
              background: colors.pendingOrange,
              borderRadius: 7,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ color: "white", fontSize: 10 }}>
              {pendingTeams.length}
            </span>
          </div>
        )}
        {hasNoTeams && (
          <div
            style={{
              height: 14,
              width: 14,
              top: -4,
              right: -4,
              position: "absolute",
              background: "tomato",
              borderRadius: 7,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ color: "white", fontSize: 10, fontWeight: "600" }}>
              !
            </span>
          </div>
        )}
      </TeamsWrapper>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setAnchorEl(null)}
      >
        <TeamsOpenWrapper>
          <div style={{ padding: 10 }}>
            {hasNoTeams ? (
              <div>
                <TeamsBoxHead />
                <div style={{ height: 8 }} />
                <div
                  style={{
                    width: "100%",
                    height: 120,
                    borderRadius: 4,
                    background: "#eeeeee80",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    border: "2px solid #eeeeee",
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <DynamicTeamsIcon color="tomato" />
                  <div style={{ height: 2 }} />

                  <span
                    style={{
                      fontSize: 13,
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    You Have No Teams
                  </span>
                  <div style={{ height: 4 }} />
                  <span style={{ color: "darkgrey", fontSize: 12 }}>
                    Spiffy works best with a team. Add your place of work to
                    receive modules.
                  </span>
                </div>
              </div>
            ) : (
              <>
                <TeamsBoxHead />
                <div
                  style={{
                    width: "100%",
                    flex: 1,
                    flexDirection: "column",
                    display: "flex",
                    gap: 6,
                    padding: "6px 0px",
                  }}
                >
                  {hasApprovedTeams && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          marginBottom: 2,
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CheckCircle
                            color="mediumseagreen"
                            height={11}
                            width={11}
                          />
                          <span
                            style={{
                              fontWeight: "500",
                              color: "mediumseagreen",
                              marginLeft: 4,
                            }}
                          >
                            Approved Teams
                          </span>
                        </div>
                        <MoreInfo
                          title="What does this mean?"
                          body="You must be approved by team administrators before having access to a teams content. These teams have approved you. Modules assigned by this team will be available to you."
                        />
                      </div>
                      {approvedTeams?.map((team) => (
                        <DisplayTeam
                          setLeaveId={setSelectedTeamId}
                          team={team}
                          key={team?.id}
                          leaveId={selectedTeamId}
                        />
                      ))}
                    </>
                  )}
                  {hasPendingTeams && (
                    <>
                      <div style={{ height: 2 }} />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 2,
                          }}
                        >
                          <AlertTriangle
                            height={11}
                            width={11}
                            color={colors.pendingOrange}
                          />
                          <span
                            style={{
                              fontWeight: "500",
                              marginLeft: 4,
                              color: colors.pendingOrange,
                            }}
                          >
                            Pending Teams
                          </span>
                        </div>
                        <MoreInfo
                          title="What does this mean?"
                          body="Pending teams have NOT approved you yet. Modules from pending teams will NOT be available to you until you are approved. It is up to administrators at this team to approve you."
                        />
                      </div>
                      {pendingTeams?.map((team) => (
                        <DisplayTeam
                          setLeaveId={setSelectedTeamId}
                          team={team}
                          key={team?.id}
                          leaveId={selectedTeamId}
                        />
                      ))}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
          <Collapse in={Boolean(selectedTeamId)}>
            <div
              style={{
                width: "100%",
                background: "#eeeeee",
                height: 40,
                borderBottomRightRadius: 8,
                borderBottomLeftRadius: 8,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0px 8px",
                gap: 8,
              }}
            >
              <Tooltip title="Edit Team Location" arrow>
                <CustomButton
                  onClick={() => setEditLocationOpen(true)}
                  sx={{
                    height: 26,
                  }}
                  startIcon={<Edit height={12} width={12} color="slategrey" />}
                  variant="text"
                  size="small"
                  color="secondary"
                >
                  Location
                </CustomButton>
              </Tooltip>
              <Tooltip title="Edit Team Roles" arrow>
                <CustomButton
                  sx={{
                    height: 26,
                  }}
                  onClick={() => {
                    setEditRolesOpen(true);
                  }}
                  startIcon={<Edit height={12} width={12} color="slategrey" />}
                  variant="text"
                  size="small"
                  color="secondary"
                >
                  Roles
                </CustomButton>
              </Tooltip>
              <div style={{ marginLeft: "auto" }} />
              <Tooltip title="Leave Selected Team" arrow>
                <Button
                  onClick={handleLeaveTeam}
                  style={{
                    background: isLeaving ? "white" : "firebrick",
                    border: isLeaving
                      ? "1px solid firebrick"
                      : "1px solid firebrick",
                    color: isLeaving ? "firebrick" : "white",
                  }}
                  size="small"
                  sx={{
                    height: 26,
                  }}
                >
                  Leave Team
                </Button>
              </Tooltip>
            </div>
          </Collapse>
        </TeamsOpenWrapper>
      </Popover>
      <Modal
        open={Boolean(editRolesOpen)}
        keepMounted={Boolean(editRolesOpen)}
        onClose={(e) => {
          e.stopPropagation();

          setEditRolesOpen(false);
        }}
      >
        <div>
          <ModifyRolesModal
            team={data?.teams?.find((t) => t.id === selectedTeamId)}
            setModifyRolesInfo={setEditRolesOpen}
            modifyRolesInfo={{
              companyId: data?.teams?.find((t) => t.id === selectedTeamId)
                ?.company__id,
              companyName: data?.teams?.find((t) => t.id === selectedTeamId)
                ?.company__name,
            }}
          />
        </div>
      </Modal>
      <Modal
        open={editLocationOpen}
        onClose={(e) => {
          setEditLocationOpen(false);
        }}
      >
        <div>
          <ModifyTeamLocationModal
            teamId={selectedTeamId}
            teamName={
              data?.teams?.find((t) => t.id === selectedTeamId)?.company__name
            }
            setOpenModifyLocation={setEditLocationOpen}
          />
        </div>
      </Modal>
    </div>
  );
};

export default TeamsDropdown;
