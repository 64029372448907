import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import styled from "styled-components/macro";
import { MODULE_TYPES } from "../../../../constants/common";

const StyledMenuItem = styled(MenuItem)`
  font-weight: 500;
`;

const FilterModuleTypeSelect = ({
  showModules,
  setShowModules,
  fullWidth = false,
}) => {
  return (
    <FormControl
      style={{
        width: fullWidth ? "100%" : 180,
        height: 30,
        background: "white",
        padding: 0,
        borderRadius: 4,
      }}
    >
      <Select
        disablePortal
        sx={{
          "& .MuiSelect-select": {
            padding: 1,
            height: 24,
            borderRadius: 4,
          },
        }}
        MenuProps={{ disablePortal: fullWidth }}
        onChange={(e) => setShowModules(e.target.value)}
        renderValue={(value) => (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              alignSelf: "center",
              height: 28,
              gap: 6,
              overflow: "hidden",
              paddingLeft: 8,
            }}
          >
            <span
              style={{
                fontWeight: "500",
                textTransform: "capitalize",
                color:
                  value === MODULE_TYPES.ALL
                    ? "black"
                    : value === MODULE_TYPES.ASSIGNED
                      ? "rgb(88, 125, 234)"
                      : "rgb(170, 151, 0)",
              }}
            >
              {value === MODULE_TYPES.ALL
                ? "All Module Types"
                : value === MODULE_TYPES.ASSIGNED
                  ? "Assigned Modules"
                  : "Extra Credit Modules"}
            </span>
          </div>
        )}
        value={showModules}
      >
        <StyledMenuItem value={MODULE_TYPES.ALL}>
          All Module Types
        </StyledMenuItem>
        <StyledMenuItem value={MODULE_TYPES.ASSIGNED}>
          <span style={{ color: "rgb(88, 125, 234)" }}>Assigned Modules</span>
        </StyledMenuItem>
        <StyledMenuItem value={MODULE_TYPES.EXTRA_CREDIT}>
          <span style={{ color: "rgb(170, 151, 0)" }}>
            Extra Credit Modules
          </span>
        </StyledMenuItem>
      </Select>
    </FormControl>
  );
};

export default FilterModuleTypeSelect;
