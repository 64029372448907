import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router";
import useAuth from "../store/auth";

const ProtectedRoute = ({ children }) => {
  const auth = useAuth();
  const { authenticated } = auth;

  if (!authenticated) {
    return <Navigate to="/signin" />;
  }

  return children;
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
