import { InputAdornment, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import React from "react";
import { Calendar } from "react-feather";
import { CustomTextField } from "../../../styles/forms";

const DobField = ({ touched, errors, setFieldValue, values }) => (
  <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        label="Date of Birth"
        type="date"
        openTo="year"
        disableFuture
        closeOnSelect
        formatDensity="spacious"
        name="dob"
        showToolbar={false}
        value={values.dob}
        // autoFocus={false}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            size="small"
            fullWidth
            helperText={touched.dob && errors.dob}
            error={Boolean(touched.dob && errors.dob)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Calendar />
                </InputAdornment>
              ),
            }}
          />
        )}
        onChange={(value) => {
          setFieldValue("dob", Date.parse(value));
        }}
      />
    </LocalizationProvider>
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginBottom: 8,
      }}
    >
      <div style={{ padding: 2, borderWidth: 1 }}>
        <Tooltip
          arrow
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                backgroundColor: "#eeeeee",
                border: "1px solid gainsboro",
              },
            },
          }}
          title={
            <div>
              <p
                style={{
                  color: "black",
                  // color: 'darkgray',
                  fontWeight: "500",
                }}
              >
                Why do we need this?
              </p>
              <p style={{ color: "black", fontWeight: "400", lineHeight: 1.2 }}>
                Some of our modules are age-restricted, and your date of birth
                helps us ensure compliance with legal restrictions. Your data is
                kept confidential.
              </p>
            </div>
          }
        >
          <span
            style={{
              fontSize: 11,
              color: "#01579b",
              fontWeight: "500",
              cursor: "pointer",
            }}
          >
            Why do we need this?
          </span>
        </Tooltip>
      </div>
    </div>
  </>
);

export default DobField;
