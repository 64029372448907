import { grey } from "@mui/material/colors";

const typography = {
  fontFamily: ["Roboto", "sans-serif"].join(","),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightBold: 500,
  h1: {
    fontSize: "2rem",
    fontWeight: 700,
    lineHeight: 1.25,
  },
  h2: {
    fontSize: "1.75rem",
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h4: {
    fontSize: "1.25rem",
    fontWeight: 400,
    lineHeight: 1.25,
  },
  h5: {
    fontSize: "1.125rem",
    fontWeight: 400,
    lineHeight: 1.25,
  },
  h6: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: 1.25,
    fontFamily: "Roboto",
  },
  body1: {
    fontSize: 12,
    fontFamily: "Roboto",
  },
  subtitle1: {
    fontSize: 14,
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Roboto",
  },
  subtitleSmall: {
    fontSize: 11,
    color: grey[500],
    fontWeight: 300,
    fontFamily: "Roboto",
    lineHeight: 1.1,
  },
  button: {
    textTransform: "none",
    fontSize: 18,
  },
};

export default typography;
