import React from "react";
import { Snackbar, Alert } from "@mui/material";
import styled from "styled-components/macro";
import useSnack from "../store/snack";
import {
  ThumbsUp,
  ThumbsDown,
  AlertTriangle,
  AlertCircle,
} from "react-feather";
import { SNACK_TYPES } from "../store/snack";

const CustomSnack = styled(Snackbar)`
  .MuiPaper-root {
    background-color: rgb(22, 29, 50);
    color: ${(props) => props.theme.palette.background.bw};
    border-radius: 12px;
    min-width: 240px;
  }
`;

const getSnackMeta = (snackType) => {
  if (!snackType) return null;

  if (snackType === SNACK_TYPES.SUCCESS) {
    return {
      Icon: ThumbsUp,
      highlightColor: "mediumseagreen",
      titleText: "Succcess",
    };
  }

  if (snackType === SNACK_TYPES.ERROR) {
    return {
      Icon: ThumbsDown,
      highlightColor: "tomato",
      titleText: "Error",
    };
  }

  if (snackType === SNACK_TYPES.WARNING) {
    return {
      Icon: AlertTriangle,
      highlightColor: "darkorange",
      titleText: "Warning",
    };
  }

  if (snackType === SNACK_TYPES.INFO) {
    return {
      Icon: AlertCircle,
      highlightColor: "dodgerblue",
      titleText: "Info",
    };
  }
};

const GlobalSnack = () => {
  const { snack } = useSnack();
  const { snackText, closeSnack, snackType } = snack;

  if (!snackType || !snackText) return null;

  const { highlightColor, titleText, Icon } = getSnackMeta(snackType);

  return (
    <CustomSnack
      autoHideDuration={6000}
      open={Boolean(snackText)}
      onClose={() => closeSnack()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      {snackText && (
        <Alert
          sx={
            {
              // boxShadow: "0px 0px 4px 2px lightgray",
            }
          }
          icon={
            <Icon
              height={18}
              width={18}
              style={{ color: highlightColor, marginTop: 4 }}
            />
          }
          severity={snackType}
        >
          <p
            style={{
              color: highlightColor,
              fontWeight: 600,
              letterSpacing: 1,
              fontSize: 14,
            }}
          >
            {titleText}
          </p>
          <p style={{ color: "white", fontSize: 13 }}>{snackText}</p>
        </Alert>
      )}
    </CustomSnack>
  );
};

export default GlobalSnack;
