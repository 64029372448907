import { Paper } from "@mui/material";
import PropTypes from "prop-types";
import React, { useReducer } from "react";
import styled from "styled-components/macro";
import Confirm from "./Confirm";
import SelectLocation from "./SelectLocation";
import SelectRoles from "./SelectRoles";
import SelectTeam from "./SelectTeam";

const AddTeamPaper = styled(Paper)`
  width: min(94vw, 1000px);

  min-height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 2px solid black;
  background: #eeeeee;

  @media (max-width: 500px) {
    paddding: 8px;
  }
`;

const StepperItem = styled.div`
  flex: 0.3333;
  height: 38px;
  border: ${(props) =>
    props.$active ? "1px solid slategrey" : "1px solid lightgray"};
  padding: 0px 12px;
  border-radius: 8px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    color: ${(props) => props.theme.palette.secondary.dark};
    font-size: 13px;
  }

  svg {
    color: ${(props) => {
      if (props.$selected) {
        return "black";
      }
      return "darkgrey";
    }};
    height: 16px;
    width: 16px;
    margin: 0px 8px;
  }
`;

const AddTeamHeader = styled.div`
  width: 100%;
  background: #eeeeee;
  gap: 8px;
  border-radius: 8px;
  display: flex;

  @media (max-width: 500px) {
    display: none;
  }
`;

const AddTeamBody = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid lightgray;
  border-radius: 6px;
  background: white;
`;

const initialState = {
  selectedCompany: null,
  selectedLocation: null,
  selectedRoles: null,
  index: 0,
};

const ACTIONS = {
  ADD_SELECTED_COMPANY: "addSelectedCompany",
  ADD_SELECTED_LOCATION: "addSelectedLocation",
  ADD_SELECTED_ROLES: "addSelectedRoles",
  SET_INDEX: "setIndex",
  REMOVE_SELECTED_ROLES: "removeSelectedRoles",
  REMOVE_SELECTED_LOCATION: "removeSelectedLocation",
  REMOVE_SELECTED_COMPANY: "removeSelectedCompany",
  RESET: "reset",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_INDEX:
      return {
        ...state,
        index: action.payload,
      };
    case ACTIONS.ADD_SELECTED_COMPANY:
      return {
        ...state,
        index: 1,
        selectedCompany: action.payload,
        selectedLocation: null,
        selectedRoles: null,
      };
    case ACTIONS.ADD_SELECTED_LOCATION:
      return {
        ...state,
        index: 2,
        selectedLocation: action.payload,
        selectedRoles: null,
      };
    case ACTIONS.ADD_SELECTED_ROLES:
      return {
        ...state,
        selectedRoles: action.payload,
        index: 3,
      };
    case ACTIONS.REMOVE_SELECTED_ROLES:
      return {
        ...state,
        index: 2,
        selectedRoles: null,
      };
    case ACTIONS.REMOVE_SELECTED_LOCATION:
      return {
        ...state,
        index: 1,
        selectedLocation: null,
      };
    case ACTIONS.REMOVE_SELECTED_COMPANY:
      return {
        ...state,
        index: 0,
        selectedCompany: null,
      };
    case ACTIONS.RESET:
      return initialState;
    default:
      return {
        ...state,
      };
  }
};

const COMPONENT_MAP = {
  0: SelectTeam,
  1: SelectLocation,
  2: SelectRoles,
  3: Confirm,
};

const AddTeam = ({ setAddTeamModalOpen }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedCompany, selectedLocation, selectedRoles, index } = state;

  const showSelectedRoles = (rolesArr) => {
    if (rolesArr.length === 1) {
      return rolesArr[0].name;
    }
    return `${rolesArr[0].name} + ${rolesArr.length - 1} others`;
  };

  const StepComponent = COMPONENT_MAP[index];

  return (
    <AddTeamPaper>
      <AddTeamHeader>
        <StepperItem $active={index === 0} $selected={Boolean(selectedCompany)}>
          {selectedCompany ? (
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <span style={{ color: "darkgrey", fontSize: 12 }}>Team:</span>
              <span style={{ fontSize: 12, fontWeight: "500" }}>
                {selectedCompany.name}
              </span>
            </div>
          ) : (
            <span style={{ color: "darkgrey" }}>Add A Team</span>
          )}
        </StepperItem>
        <StepperItem
          $active={index === 1}
          $selected={Boolean(selectedLocation)}
        >
          {selectedLocation ? (
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <span style={{ color: "darkgrey", fontSize: 12 }}>Location:</span>

              <span style={{ fontSize: 12, fontWeight: "500" }}>
                {selectedLocation.name}
              </span>
            </div>
          ) : (
            <span style={{ color: "darkgrey" }}>Select Location</span>
          )}
        </StepperItem>
        <StepperItem $active={index === 2} selected={Boolean(selectedRoles)}>
          {selectedRoles ? (
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <span style={{ color: "darkgrey", fontSize: 12 }}>Roles:</span>
              <span style={{ fontSize: 12, fontWeight: "500" }}>
                {showSelectedRoles(selectedRoles)}
              </span>
            </div>
          ) : (
            <span style={{ color: "darkgrey" }}>Select Roles</span>
          )}
        </StepperItem>
      </AddTeamHeader>
      <div style={{ height: 8 }} />
      <AddTeamBody>
        <StepComponent
          state={state}
          dispatch={dispatch}
          ACTIONS={ACTIONS}
          setAddTeamModalOpen={setAddTeamModalOpen}
        />
      </AddTeamBody>
    </AddTeamPaper>
  );
};

export default AddTeam;

AddTeam.propTypes = {
  setAddTeamModalOpen: PropTypes.func.isRequired,
};
