import React from "react";
import styled from "styled-components/macro";
import { Paper, Button } from "@mui/material";
import { useNavigate } from "react-router";

import TeamSvg from "../../../assets/svg/teams.svg";
import { Link } from "react-router-dom";

const ModalWrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  min-height: 130px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid black;
  text-align: center;

  @media (max-width: 500px) {
    width: 96vw;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ResponsiveButton = styled(Button)`
  width: 240px;

  @media (max-width: 380px) {
    width: 200px;
  }

  @media (max-width: 320px) {
    width: fit-content;
    font-size: 10px;
  }
`;

const QrCodeRegisterInfoModal = ({
  selectedCompany,
  selectedLocation,
  handleClose,
}) => {
  const navigate = useNavigate();

  return (
    <ModalWrapper>
      <span style={{ fontWeight: "600", fontSize: 16 }}>
        Two Options To Join Team
      </span>
      <div style={{ height: 4 }} />
      <div
        style={{
          display: "flex",
          gap: 6,
          alignItems: "center",
          padding: "2px 8px",
          background: "#eeeeee",
          borderRadius: 6,
        }}
      >
        <img src={TeamSvg} alt="team" style={{ height: 12 }} />

        <span
          style={{
            fontWeight: "600",
            color: "slategrey",
          }}
        >
          {selectedCompany?.name}
        </span>
      </div>
      <div style={{ height: 12 }} />

      <p style={{ fontSize: 13, fontWeight: "400", color: "black" }}>
        If you already have a spiffy account.
      </p>
      <div style={{ height: 4 }} />
      <ResponsiveButton
        onClick={() =>
          navigate("/signin+join", {
            state: { selectedCompany, selectedLocation },
          })
        }
        size="small"
        variant="contained"
      >
        Log In + Join Team
      </ResponsiveButton>
      <div style={{ height: 12 }} />

      <p style={{ fontSize: 13, fontWeight: "400", color: "black" }}>
        If you do not have a spiffy account.
      </p>
      <div style={{ height: 4 }} />
      <ResponsiveButton onClick={handleClose} size="small" variant="contained">
        Create Account + Join Team
      </ResponsiveButton>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 12,
        }}
      >
        <div style={{ width: 12 }} />
        <span
          style={{
            fontSize: 11,
            color: "slategrey",
            textAlign: "center",
            position: "relative",
            top: 12,
          }}
        >
          Dont want to join this team?
          <ins
            style={{ marginLeft: 3, color: "#337ab7", textDecoration: "none" }}
          >
            <StyledLink to="/signin" style={{ color: "#337ab7" }}>
              Login Here
            </StyledLink>
          </ins>
        </span>
      </div>
    </ModalWrapper>
  );
};

export default QrCodeRegisterInfoModal;
