import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components/macro";
import {
  FormControl,
  Checkbox,
  Button,
  Select,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { AlertCircle, Lock } from "react-feather";
import API from "../../../axios/API";
import useAuth from "../../../store/auth";
import { GlobalState } from "../../../store/global";
import LinearProgLoader from "../../../components/Loaders/LinearProgLoader";
import { TERMS_HREF, formatDateForApi, CheckCircleGreen } from "./utils";
import * as amplitude from "@amplitude/analytics-browser";

const Wrapper = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const TextSpan = styled.span`
  color: ${(props) => props.theme.palette.background.bw};
`;

const StyledButton = styled(Button)`
  border: 1px solid black;
  color: black;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 0px;

  .MuiSvgIcon-root {
    height: 16px;
    width: 16px;
    margin-right: 0px;
    transform: translate(0px, 0px);
  }

  svg {
    height: 12px;
    width: 12px;
    margin-right: 4px;
    transform: translate(3px, -1px);
  }

  a {
    color: ${(props) => props.theme.palette.info.dark};
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const StyledSelect = styled(Select)`
  .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.background.bw} !important;
  }
`;

const FakeField = styled.div`
  padding: 6px;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 4px;
  background: #eeeeee50;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;

const QRCodeVersionOfForm = ({ dispatch, ACTIONS, formState, snack }) => {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [rolesOpen, setRolesOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setSnack, SNACK_TYPES } = snack;
  const { login } = useAuth();
  const {
    selectedCompany,
    email,
    firstName,
    lastName,
    password,
    dob,
    ref,
    selectedLocation,
  } = formState;

  const { setShowRegisterModal } = useContext(GlobalState);

  const { agreedToTerms } = formState;

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    try {
      const { data } = await API.get(
        `dashboard/signup/company/roles/?company_id=${selectedCompany.id}`,
      );
      setAvailableRoles(data.roles.map((r) => ({ id: r[0], name: r[1] })));
    } catch (error) {
      console.log(error);
      // TODO -> can do after deploy as this page is currently unused //
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    let submitObj;
    submitObj = {
      version: 2,
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      birth_date: formatDateForApi(dob),
      referral_code: ref,
      has_company: true,
      location_id: selectedLocation.id,
      roles: selectedRoles.map((r) => r.id),
    };

    try {
      const { data } = await API.post("/accounts/signup/", submitObj);
      setIsSubmitting(false);
      setShowRegisterModal(true);
      amplitude.track("Account Register [QR CODE LOCATION]");
      return login(data.token);
    } catch (error) {
      setIsSubmitting(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data === "email_unavailable"
      ) {
        return setSnack(
          "Failed to sign you up, there is already an account with this email",
          SNACK_TYPES.ERROR,
        );
      }
      return setSnack(
        "Failed to sign you up, please try again",
        SNACK_TYPES.ERROR,
      );
    }
  };

  return (
    <Wrapper>
      <span style={{ fontWeight: "500" }}>Team</span>
      <FakeField>
        <Lock height={10} width={10} color="slategrey" />
        <span>{selectedCompany?.name}</span>
      </FakeField>
      <div style={{ height: 12 }} />
      <span style={{ fontWeight: "500" }}>Location</span>
      <FakeField>
        <Lock height={10} width={10} color="slategrey" />
        <span>{selectedLocation?.name}</span>
      </FakeField>
      <FlexDiv>
        <Typography variant="subtitle2">Select Your Role</Typography>
        {selectedRoles.length === 0 ? <AlertCircle /> : <CheckCircleGreen />}
      </FlexDiv>
      <FormControl fullWidth>
        <StyledSelect
          select
          multiple
          open={rolesOpen}
          onClose={() => setRolesOpen(false)}
          onOpen={() => setRolesOpen(true)}
          fullWidth
          variant="outlined"
          size="small"
          color="secondary"
          value={selectedRoles}
          onChange={(e) => {
            setSelectedRoles(e.target.value);
            return setRolesOpen(false);
          }}
        >
          {availableRoles.map((role) => (
            <MenuItem value={role} key={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </StyledSelect>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ padding: 2 }}>
            <Tooltip
              arrow
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "#EEEEEE",
                    border: "1px solid gainsboro",
                  },
                },
              }}
              title={
                <div>
                  <p style={{ color: "black", fontWeight: "500" }}>
                    What are roles?
                  </p>
                  <p
                    style={{
                      color: "black",
                      fontWeight: "300",
                      lineHeight: 1.2,
                    }}
                  >
                    Certain modules are deployed to specific roles based on job
                    functions. Roles are used to identify what content is
                    displayed to you in your app feed.
                  </p>
                </div>
              }
            >
              <span
                style={{
                  fontSize: 11,
                  color: "#01579b",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
              >
                What are roles?
              </span>
            </Tooltip>
          </div>
        </div>
      </FormControl>

      <div style={{ height: 56, marginTop: "auto" }} />
      <FlexDiv style={{ marginTop: "auto", paddingTop: 8 }}>
        <Checkbox
          checked={agreedToTerms}
          onClick={(e) =>
            dispatch({
              type: ACTIONS.TOGGLE_AGREED,
              payload: e.target.checked,
            })
          }
          size="small"
          color="primary"
        />
        <TextSpan style={{ marginLeft: 4 }}>
          I agree to the{" "}
          <a href={TERMS_HREF} target="_blank" rel="noreferrer">
            terms and conditions
          </a>
        </TextSpan>
        {agreedToTerms ? <CheckCircleGreen /> : <AlertCircle />}
      </FlexDiv>
      <StyledButton
        type="submit"
        variant="outlined"
        color="primary"
        fullWidth
        sx={{
          p: 1,
          fontSize: 14,
          mb: 2,
          marginTop: 2,
        }}
        onClick={handleSubmit}
        disabled={selectedRoles.length === 0 || !agreedToTerms}
      >
        Finish
        {isSubmitting && <LinearProgLoader />}
      </StyledButton>
    </Wrapper>
  );
};

export default QRCodeVersionOfForm;
