import React from "react";
import PropTypes from "prop-types";
import { Popper, Paper, ClickAwayListener } from "@mui/material";
import styled from "styled-components/macro";

const Wrapper = styled(Paper)`
  padding: 10px;
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
`;

const ReuseablePopper = ({ anchorEl, setAnchorEl, children }) => {
  const open = Boolean(anchorEl);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      disablePortal
      placement="bottom-start"
    >
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <Wrapper
          sx={{
            zIndex: 100000,
            position: "relative",
          }}
        >
          {children}
        </Wrapper>
      </ClickAwayListener>
    </Popper>
  );
};

export default ReuseablePopper;

ReuseablePopper.propTypes = {
  anchorEl: PropTypes.node.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
