import React, { useContext } from "react";
import { ArrowLeft } from "react-feather";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { Button } from "@mui/material";
import styled from "styled-components/macro";

const StyledButton = styled(Button)`
  height: 33px;
  width: 33px;
  max-width: 36px;
  min-width: 0px;
  padding: 0px;
  border-radius: 8px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgray;

  &:hover {
    background: #eeeeee40;
    border: 1px solid slategrey;
  }
`;

const LeftButton = () => {
  const visibility = useContext(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible("first", true);

  return (
    <StyledButton
      disabled={isFirstItemVisible}
      onClick={() => visibility.scrollPrev()}
    >
      <ArrowLeft
        color={isFirstItemVisible ? "darkgrey" : "black"}
        height={18}
        width={18}
      />
    </StyledButton>
  );
};

export default LeftButton;
