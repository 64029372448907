import styled from "styled-components/macro";

export const Wrapper = styled.div`
  padding: 4px;
`;

export const ReviewOption = styled.div`

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding: 4px 2px;
  border-radius: 0px;
  background-color: #EEEEEE56;
  }};

  span {
    color: ${(props) => props.theme.palette.background.bg};
    font-size: 10px;
  };

  svg{
    height: 10px;
    width: 10px;
  }
`;
