export const colors = {
  pendingOrange: "darkorange",
  black: "black",
  white: "white",
  brand: "#337ab7",
  darkgray: "darkgray",
  lightgray: "lightgray",
  basegray: "#eeeeee",
  slategray: "slategrey",
  extraCreditBg: "rgb(255, 238, 176)",
  extraCreditText: "rgb(170, 151, 0)",
  assignedBg: "rgba(0, 0, 150, .1)",
  assignedText: "rgb(88, 125, 234)",
};
