import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Autocomplete, Button, CircularProgress } from "@mui/material";
import { CustomTextField } from "../../../styles/forms";
import useAllCountries from "../../../reactQueryHooks/common/useAllCountries";
import useStatesByCountry from "../../../reactQueryHooks/common/useStatesByCountry";
import API from "../../../axios/API";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LocationForm = ({
  profile,
  setSnack,
  refetch,
  SNACK_TYPES,
  setExpandedIndex,
}) => {
  const {
    isLoading,
    // error,
    data: allCountries,
  } = useAllCountries();
  const [country, setCountry] = useState(profile.country);

  const {
    data: allStates,
    // error: statesError,
    isLoading: statesLoading,
  } = useStatesByCountry(country.code);

  const [state, setState] = useState(profile.state);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const localStateGood = Boolean(country) && Boolean(state);

  const isDisabled =
    !localStateGood ||
    (state?.id === profile?.state?.id &&
      country?.code === profile?.country?.code);

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      await API.patch("accounts/profile/", {
        country: country.code,
        state: state.id,
      });
      await refetch();
      setIsSubmitting(false);
      setExpandedIndex(null);
      setSnack("Successfully updated your information", SNACK_TYPES.SUCCESS);
    } catch (e) {
      setIsSubmitting(false);
      setSnack("Failed to update your information", SNACK_TYPES.ERROR);
    }
  };

  return (
    <Wrapper>
      <Autocomplete
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        fullWidth
        noOptionsText="No Countries Found"
        value={country}
        options={isLoading ? [] : allCountries}
        sx={{
          mb: 4,
        }}
        onChange={(_, v) => {
          setCountry(v);
          setState(null);
        }}
        size="small"
        renderInput={(params) => (
          <CustomTextField {...params} variant="outlined" label="Country" />
        )}
      />
      <Autocomplete
        fullWidth
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, v) => {
          setState(v);
        }}
        variant="outlined"
        options={statesLoading ? [] : allStates}
        value={state}
        noOptionsText="No States Available"
        sx={{
          mb: 4,
        }}
        size="small"
        renderInput={(params) => (
          <CustomTextField {...params} variant="outlined" label="State" />
        )}
      />
      <Button
        type="submit"
        size="small"
        variant="contained"
        color="primary"
        disabled={isDisabled}
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        sx={{
          marginTop: "auto",
          marginLeft: "auto",
        }}
        onClick={handleSave}
      >
        Save
      </Button>
    </Wrapper>
  );
};

export default LocationForm;

LocationForm.propTypes = {
  profile: PropTypes.object.isRequired,
  setSnack: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  SNACK_TYPES: PropTypes.object.isRequired,
};
