import { InputAdornment, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { MapPin } from "react-feather";
import styled from "styled-components/macro";
import useAllCountries from "../../../reactQueryHooks/common/useAllCountries";
import useStatesByCountry from "../../../reactQueryHooks/common/useStatesByCountry";
import { CustomTextField } from "../../../styles/forms";

const Wrapper = styled.div`
  width: 100%;
`;

const CountryAndStateFields = ({ handleChange, values, setFieldValue }) => {
  const [targetCountryCode, setTargetCountryCode] = useState(null);
  const { data: countries, isLoading: countriesLoading } = useAllCountries();
  const { data: states, isLoading: statesLoading } =
    useStatesByCountry(targetCountryCode);

  return (
    <Wrapper>
      <CustomTextField
        select
        onChange={(e) => {
          setFieldValue("state", "");
          setTargetCountryCode(e.target.value);
          return handleChange(e);
        }}
        label="Country of Residence"
        fullWidth
        name="country"
        variant="outlined"
        disabled={countriesLoading}
        size="small"
        color="secondary"
        value={values.country || ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MapPin />
            </InputAdornment>
          ),
        }}
      >
        {!countries && (
          <MenuItem disabled value="">
            loading...
          </MenuItem>
        )}
        {countries?.map((country) => (
          <MenuItem key={country.code} value={country.code}>
            {country.name}
          </MenuItem>
        ))}
      </CustomTextField>
      <div style={{ height: 16 }} />
      <CustomTextField
        select
        label="State/Province of Residence"
        fullWidth
        name="state"
        disabled={statesLoading}
        variant="outlined"
        size="small"
        onChange={handleChange}
        color="secondary"
        value={values.state}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MapPin />
            </InputAdornment>
          ),
        }}
      >
        {(!states || states.length === 0) && (
          <MenuItem disabled>
            {statesLoading
              ? "loading.."
              : "No States available for this country"}
          </MenuItem>
        )}
        {states?.map((state) => (
          <MenuItem value={state.id} key={state.id}>
            {state.name}
          </MenuItem>
        ))}
      </CustomTextField>
    </Wrapper>
  );
};

export default CountryAndStateFields;
