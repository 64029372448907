import { useQuery } from "react-query";
import API from "../../axios/API";

const getTeams = async () => {
  const { data } = await API.get("v2/teams/");
  return data;
};

const useTeams = () => useQuery("teams", getTeams);

export default useTeams;
