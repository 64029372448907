import React from "react";
import styled from "styled-components/macro";
import { IconButton, Paper } from "@mui/material";
import CompletionDetails from "./CompletionDetails";
import { X } from "react-feather";

const Wrapper = styled(Paper)`
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  min-height: 440px;
  min-width: 820px;

  border: 2px solid black;
  background: ${(props) => props.theme.palette.background.paper};

  @media (max-width: 840px) {
    min-width: 96vw;
  }
`;

const SubmissionReviewModal = ({ reviewSubmissionInfo, handleClose }) => {
  return (
    <Wrapper>
      <div
        style={{
          width: "100%",

          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 6px 0px 16px",
          background: "seagreen",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 4,
          }}
        >
          <span style={{ fontSize: 16, fontWeight: "600", color: "white" }}>
            Review Submission
          </span>
          <span style={{ color: "#eeeeee", fontSize: 18 }}>/</span>
          <span style={{ color: "#eeeeee", fontSize: 15 }}>
            # {reviewSubmissionInfo.id}
          </span>
        </div>

        <IconButton onClick={handleClose}>
          <X color="white" />
        </IconButton>
      </div>
      <CompletionDetails reviewInfo={reviewSubmissionInfo} />
    </Wrapper>
  );
};

export default SubmissionReviewModal;
