import { Button, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Check, Search } from "react-feather";
import styled from "styled-components/macro";
import { useDebounce } from "use-debounce";
import API from "../../../axios/API";
import DynamicTeamsIcon from "../../Header/TeamsDropdown/DynamicTeamsIcon";
import { CustomTextField } from "../../../styles/forms";
import {
  ButtonHolder,
  Centered,
  Option,
  ShowSearchResults,
  TitleWrapper,
} from "./styles";

const CustomResponsiveTextfield = styled(CustomTextField)`
  width: 340px;

  @media (max-width: 500px) {
    width: 200px;
  }
`;

const Wrapper = styled.div`
  padding: 6px 12px;
  width: 100%;
`;

const NoResultsSpan = styled.span`
  color: darkgrey;
  font-size: 13px;
`;

const SelectTeam = ({ state, dispatch, ACTIONS }) => {
  const [search, setSearch] = useState("");
  const [fetching, setFetching] = useState(false);
  const [selected, setSelected] = useState(null);
  const [options, setOptions] = useState([]);
  const searchRef = useRef(null);

  const [searchText] = useDebounce(search, 200);

  useEffect(() => {
    if (searchText.length < 3) {
      setOptions([]);
      return undefined;
    }
    const searchCompany = async () => {
      setFetching(true);
      try {
        const { data } = await API.get(
          `/teams/search/company/?search=${searchText}`,
        );
        setOptions(data.companies);
        if (selected) {
          // check to see if the currently selected is still avail in the options
          const selectedAvail = data.companies.some(
            (company) => company.id === selected.id,
          );
          if (!selectedAvail) {
            setSelected(null);
          }
        }
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };

    searchCompany();
  }, [searchText]);

  useEffect(() => {
    if (searchRef?.current) {
      searchRef?.current?.focus();
    }
  }, []);

  return (
    <Wrapper>
      <TitleWrapper>
        <span style={{ fontSize: 18, fontWeight: "600", whiteSpace: "nowrap" }}>
          Add A Team
        </span>
        <CustomResponsiveTextfield
          type="text"
          size="small"
          inputRef={searchRef}
          autoComplete="off"
          value={search}
          placeholder="Search..."
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
          onChange={(e) => setSearch(e.target.value)}
          color="primary"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </TitleWrapper>
      <ShowSearchResults>
        {options.length === 0 ? (
          <Centered>
            <NoResultsSpan>
              {fetching
                ? "loading..."
                : search.length > 2
                  ? "No results for this search"
                  : "Use search to find teams"}
            </NoResultsSpan>
          </Centered>
        ) : (
          <>
            {options.map((option) => (
              <Option
                selected={selected?.id === option?.id ? "y" : "n"}
                key={option.id}
                onClick={() => setSelected(option)}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <DynamicTeamsIcon small />

                  <span style={{ fontSize: 13 }}>{option.name}</span>
                </div>
                {selected && selected.id === option.id ? (
                  <div
                    style={{
                      height: 13,
                      width: 13,
                      borderRadius: 3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      background:
                        selected && selected.id === option.id
                          ? "#337ab7"
                          : "#eeeeee",
                    }}
                  >
                    <Check
                      color={
                        selected && selected.id === option.id
                          ? "white"
                          : "transparent"
                      }
                      height={10}
                      width={10}
                    />
                  </div>
                ) : (
                  // <SelectedIcon />
                  <div />
                )}
              </Option>
            ))}
          </>
        )}
      </ShowSearchResults>
      <ButtonHolder>
        <Button
          color="primary"
          variant="contained"
          size="small"
          disabled={!selected && !state.selectedCompany}
          onClick={() => {
            setSearch("");
            setOptions([]);
            if (selected) {
              return dispatch({
                type: ACTIONS.ADD_SELECTED_COMPANY,
                payload: selected,
              });
            }
            return dispatch({ type: ACTIONS.SET_INDEX, payload: 1 });
          }}
        >
          Next Step
        </Button>
      </ButtonHolder>
    </Wrapper>
  );
};

export default SelectTeam;

SelectTeam.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
};
