import React, { useState } from "react";
import { Paper } from "@mui/material";
import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import AcceptOptions from "./AcceptOptions";
import AcceptForm from "./AcceptForm";
import PublicFormLayout from "../../../layouts/PublicFormLayout";

const Wrapper = styled(Paper)`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 20px;
  position: relative;

  @media (max-width: 420px) {
    width: 96vw;
    padding: 10px;
    min-width: 300px;
  }
`;

const AcceptReferral = () => {
  const params = useParams();
  const { token } = params || {};
  const [step, setStep] = useState(0);

  return (
    <PublicFormLayout>
      <Wrapper>
        {step === 0 && <AcceptOptions setStep={setStep} token={token} />}
        {step === 1 && <AcceptForm setStep={setStep} token={token} />}
      </Wrapper>
    </PublicFormLayout>
  );
};

export default AcceptReferral;
