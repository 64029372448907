import { Button, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { ArrowLeft } from "react-feather";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  padding: 8px 14px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${(props) => props.theme.palette.background.contrast};

  @media (max-width: 500px) {
    max-width: 98vw;
  }
`;

const BackButton = styled(Button)`
  height: 36px;
  width: 36px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 36px;
  max-width: 36px;
  min-height: 36px;
  min-width: 36px;
  background: white;
  border: 1px solid #eeeeee;
  transition: 200ms ease-in;

  svg {
    color: slategrey;
  }

  &:hover {
    background: #eeeeee;

    svg {
      color: black;
    }
  }
`;

const TitleBox = styled.div`
  width: fit-content;
  padding: 4px 12px;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-radius: 4px;
  height: 36px;
  min-height: 36px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 0px 4px 2px #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: "Raleway", sans-serif;
    white-space: nowrap;

    font-size: 18px;
    font-weight: 600;
  }
`;

const HoldChildren = styled.div`
  justify-self: flex-end;
  flex-grow: 1;
`;

const PageHeader = ({ text, isNested = false, children }) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      {isNested && (
        <>
          <Tooltip arrow title="go back">
            <BackButton onClick={() => navigate(-1)}>
              <ArrowLeft />
            </BackButton>
          </Tooltip>
          <div style={{ width: 6 }} />
        </>
      )}
      <TitleBox>
        <span>{text}</span>
      </TitleBox>

      {children && <HoldChildren>{children}</HoldChildren>}
    </Wrapper>
  );
};

export default PageHeader;

PageHeader.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
  isLearnPage: PropTypes.bool,
};

PageHeader.defaultProps = {
  isLearnPage: false,
  children: undefined,
};
