import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Check, MapPin } from "react-feather";
import { Option } from "./styles";

const ResultPin = styled(MapPin)`
  margin-right: 8px;
  height: 12px;
  width: 12px;
  color: ${(props) => props.theme.palette.background.bw};
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddressLine2Text = styled.span`
  color: darkgrey;

  @media (max-width: 500px) {
    display: none;
  }
`;

const LocationOption = ({ loc, selected, setSelected }) => {
  const { line1, city, country } = loc.address;

  return (
    <Option
      selected={selected?.id === loc.id ? "y" : "n"}
      onClick={() => setSelected(loc)}
    >
      <FlexDiv>
        <ResultPin style={{ color: "darkgrey" }} />
        <span style={{ fontSize: 13 }}>{loc.name}</span>
        <div style={{ width: 6 }} />
        <AddressLine2Text>{`${line1}, ${city.name}, ${country.name}`}</AddressLine2Text>
      </FlexDiv>

      <div
        style={{
          height: 13,
          width: 13,
          borderRadius: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background:
            selected && selected?.id === loc.id ? "#337ab7" : "#eeeeee",
        }}
      >
        <Check
          color={selected && selected?.id === loc.id ? "white" : "transparent"}
          height={10}
          width={10}
        />
      </div>
    </Option>
  );
};

export default LocationOption;

LocationOption.propTypes = {
  loc: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
};
