import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Paper, Typography, Button, CircularProgress } from "@mui/material";
import { DropzoneArea } from "mui-file-dropzone";
import { useQueryClient } from "react-query";
import Cropper from "react-easy-crop";
import UPLOAD_IMAGE from "../../../assets/svg/upload-image.svg";
import { ExitModal } from "../../../styles/buttons";
import API from "../../../axios/API";
import useSnack from "../../../store/snack";

const Wrapper = styled(Paper)`
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  padding: 20px;
  min-height: 240px;
  width: 520px;
  border: 2px solid black;
  background: ${(props) => props.theme.palette.background.paper};
`;

const RegisterSVG = styled.img`
  margin-top: auto;
  height: 120px;
  width: 120px;
`;

const DropzoneContainer = styled.div`
  .MuiBox-root {
    min-height: 160px;
    width: 240px;
    background-color: ${(props) => props.theme.palette.background.light};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 12px;

    p {
      font-size: 14px;
      margin-bottom: -2px;
      margin-top: -8px;
    }
  }
`;

const CropWrapper = styled.div`
  padding: 4px;
  margin-top: 0px;
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  margin-top: 4px;
`;

const StyledCropper = styled(Cropper)`
  .reactEasyCrop_CropArea .reactEasyCrop_CropAreaGrid {
    color: #eeeeee73;
  }
`;

const HoldCrop = styled.div`
  height: 160px;
  width: 240px;
  position: relative;
`;

const AfterCropContainer = styled.div`
  width: 240px;
  padding: 4px;
  object-fit: cover;
  border: 3px dashed #eeeeee;

  img {
    height: 140px;
    width: 140px;
    border-radius: 50%;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

const UploadProfilePhoto = ({ setShowUploadModal }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isUploading, setIsUploading] = useState(false);
  const [afterCrop, setAfterCrop] = useState(null);
  const [complete, setComplete] = useState(null);
  const [backup, setBackup] = useState(null);
  const [newFile, setNewFile] = useState("");
  const queryClient = useQueryClient();
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;

  const getCroppedImg = async () => {
    const image = await createImage(newFile);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = 500;
    canvas.height = 500;

    ctx.drawImage(
      image,
      complete.x,
      complete.y,
      complete.width,
      complete.height,
      0,
      0,
      canvas.width,
      canvas.height,
    );
    canvas.toBlob(
      (blob) => {
        const newUrl = URL.createObjectURL(blob, "");
        setAfterCrop(newUrl);
        setNewFile(blob);
      },
      "image/png",
      1,
    );
  };

  const handleUpload = async (blob) => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append("photo", blob);
      await API.post("/users/profile/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      queryClient.invalidateQueries("users");
      queryClient.invalidateQueries("profile");
      setNewFile("");
      setSnack("Successfully updated profile picture", SNACK_TYPES.SUCCESS);
      return setShowUploadModal(false);
    } catch (error) {
      setSnack(
        "Failed to update profile picture, please try again",
        SNACK_TYPES.ERROR,
      );
      setNewFile("");
      setBackup(null);
      setAfterCrop(null);
      setComplete(null);
      return setIsUploading(false);
    }
  };

  return (
    <Wrapper>
      <ExitModal onClick={() => setShowUploadModal(false)} />
      <Typography style={{ fontSize: 18, fontWeight: "500" }} align="left">
        Profile Image Manager
      </Typography>
      <FlexDiv>
        <DropzoneContainer>
          {!newFile && (
            <CropWrapper>
              <Typography
                variant="subtitle1"
                color="secondary"
                sx={{ mt: -2, mb: 2 }}
              >
                Add an image
              </Typography>
              <DropzoneArea
                acceptedFiles={["image/*", "image/png", "image/jpg"]}
                showAlerts={false}
                showFileNames
                filesLimit={1}
                dropzoneText="Drop an image or click here"
                onChange={(file) => {
                  if (!file) return;
                  if (file.length === 0) return;
                  const fileUrl = URL.createObjectURL(file[0]);
                  setBackup(fileUrl);
                  setNewFile(fileUrl);
                }}
              />
            </CropWrapper>
          )}
          {newFile.length > 0 && (
            <CropWrapper>
              <Typography
                variant="subtitle1"
                color="secondary"
                sx={{ mt: -2, mb: 2 }}
              >
                Crop your image
              </Typography>
              <HoldCrop>
                <StyledCropper
                  image={newFile}
                  crop={crop}
                  zoom={zoom}
                  cropShape="round"
                  aspect={1 / 1}
                  minZoom={0.52}
                  restrictPosition
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={(_, b) => setComplete(b)}
                />
              </HoldCrop>
              <ButtonHolder>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  fullWidth
                  sx={{ mr: 1 }}
                  onClick={() => {
                    setNewFile("");
                    setBackup(null);
                  }}
                >
                  Go back
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  fullWidth
                  sx={{ ml: 1 }}
                  onClick={getCroppedImg}
                >
                  Continue
                </Button>
              </ButtonHolder>
            </CropWrapper>
          )}
          {afterCrop && (
            <CropWrapper>
              <Typography
                variant="subtitle1"
                color="secondary"
                sx={{ mt: -2, mb: 2 }}
              >
                Final Image
              </Typography>
              <AfterCropContainer>
                <img src={afterCrop} alt="" />
              </AfterCropContainer>
              <ButtonHolder>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  fullWidth
                  sx={{ mr: 1 }}
                  onClick={() => {
                    setNewFile(backup);
                    setAfterCrop(null);
                  }}
                >
                  Back to crop
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  fullWidth
                  startIcon={
                    isUploading ? <CircularProgress size={20} /> : null
                  }
                  disabled={isUploading}
                  sx={{ ml: 1 }}
                  onClick={() => handleUpload(newFile)}
                >
                  {isUploading ? "Uploading.." : "Use image"}
                </Button>
              </ButtonHolder>
            </CropWrapper>
          )}
        </DropzoneContainer>
        <RegisterSVG src={UPLOAD_IMAGE} />
      </FlexDiv>
    </Wrapper>
  );
};

export default UploadProfilePhoto;

UploadProfilePhoto.propTypes = {
  setShowUploadModal: PropTypes.func.isRequired,
};
