import React from "react";
import { useContext, useMemo, lazy, Suspense } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { Routes, Route } from "react-router-dom";
import SignIn from "./pages/public/SignIn";
import Register from "./pages/public/Register";
import Auth from "./layouts/Auth";
import Dashboard from "./layouts/Dashboard";
import GlobalSnack from "./components/GlobalSnack";
import Learn from "./pages/private/Learn";
import getTheme from "./theme";
import { GlobalState } from "./store/global";
import ProtectedRoute from "./routes/ProtectedRoute";
import ForgotPassword from "./pages/public/ForgotPassword";
import AcceptInvite from "./pages/public/AcceptInvite";
import AcceptReferral from "./pages/public/AcceptReferral";
import ModuleLayout from "./layouts/ModuleLayout";
import LoginAndJoinTeam from "./pages/public/LoginAndJoinTeam";
import * as amplitude from "@amplitude/analytics-browser";

const Profile = lazy(() => import("./pages/private/Profile"));
const Contact = lazy(() => import("./pages/private/Contact"));
const NoMatch = lazy(() => import("./pages/public/NoMatch"));
const Invite = lazy(() => import("./pages/private/Invite"));
const DeleteAccount = lazy(
  () => import("./pages/private/DeleteAccountWithAuth"),
);
const BrandOverview = lazy(() => import("./pages/private/BrandOverview"));
const ExploreV2 = lazy(() => import("./pages/private/ExploreV2"));
const Review = lazy(() => import("./pages/private/Review"));
const ModuleV2 = lazy(() => import("./pages/private/ModuleV2"));
const LeaderboardsV2 = lazy(() => import("./pages/private/LeaderboardsV2"));

function App() {
  const { themeLight } = useContext(GlobalState);
  const myTheme = useMemo(() => getTheme(themeLight), [themeLight]);

  amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, {});

  return (
    <div className="App">
      <StyledThemeProvider theme={myTheme}>
        <ThemeProvider theme={myTheme}>
          <CssBaseline />
          <Routes>
            <Route element={<Auth />}>
              <Route path="signin/" element={<SignIn />} />
              <Route path="register/" element={<Register />} />
              <Route path="signin+join" element={<LoginAndJoinTeam />} />
              <Route path="forgot-password/" element={<ForgotPassword />} />
              <Route path="invitation/:token" element={<AcceptInvite />} />
              <Route path="referral/:token" element={<AcceptReferral />} />
            </Route>
            <Route element={<ModuleLayout />}>
              <Route
                path="moduleV2/:id/"
                element={
                  <ProtectedRoute>
                    <Suspense>
                      <ModuleV2 />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route element={<Dashboard />}>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Learn />
                  </ProtectedRoute>
                }
              />
              <Route
                path="profile/"
                element={
                  <ProtectedRoute>
                    <Suspense>
                      <Profile />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              <Route
                path="module/:id/"
                element={
                  <ProtectedRoute>
                    <ModuleV2 />
                  </ProtectedRoute>
                }
              />
              <Route
                path="review/"
                element={
                  <ProtectedRoute>
                    <Suspense>
                      <Review />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              <Route
                path="explore/"
                element={
                  <ProtectedRoute>
                    <Suspense>
                      <ExploreV2 />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              <Route
                path="explore/:id"
                element={
                  <ProtectedRoute>
                    <Suspense>
                      <BrandOverview />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              <Route
                path="invite/"
                element={
                  <ProtectedRoute>
                    <Suspense>
                      <Invite />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              <Route
                path="contact/"
                element={
                  <ProtectedRoute>
                    <Suspense>
                      <Contact />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              <Route
                path="leaderboards/"
                element={
                  <ProtectedRoute>
                    <Suspense>
                      <LeaderboardsV2 />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              <Route
                path="delete-personal-data/"
                element={
                  <ProtectedRoute>
                    <Suspense>
                      <DeleteAccount />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="*"
              element={
                <Suspense>
                  <NoMatch />
                </Suspense>
              }
            />
          </Routes>
          <GlobalSnack />
        </ThemeProvider>
      </StyledThemeProvider>
    </div>
  );
}

export default App;
