import { useQuery } from "react-query";
import API from "../../axios/API";

const getTeamRoles = async (teamId) => {
  const { data } = await API.get(`companies/roles/?company_id=${teamId}`);
  return data;
};

const useTeamRoles = (teamId) =>
  useQuery(["companyRoles", teamId], () => getTeamRoles(teamId));

export default useTeamRoles;
