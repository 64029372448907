import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import styled from "styled-components/macro";
import useTeamRoles from "../../../../reactQueryHooks/teams/useTeamRoles";
import API from "../../../../axios/API";
import { ExitModal } from "../../../../styles/buttons";
import { useQueryClient } from "react-query";
import useSnack from "../../../../store/snack";

const ModalWrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 240px;
  transform: translate(-50%, -50%);
  width: min(500px, 96%);

  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  max-height: 80vh;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
`;

const RoleContainer = styled.div`
  width: 100%;
  padding: 0px 4px;
  margin-bottom: 4px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;

  &:hover {
    background-color: #eeeeee34;
    cursor: pointer;
  }
`;

const ModifyRolesModal = ({ modifyRolesInfo, team, setModifyRolesInfo }) => {
  const { companyId } = modifyRolesInfo;

  const { error, isLoading, data } = useTeamRoles(companyId);

  const queryClient = useQueryClient();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;

  useEffect(() => {
    if (isLoading) return undefined;

    const currentRoleIds = team.tags.map((t) => t.id);
    setSelectedRoles(currentRoleIds);
  }, [isLoading]);

  if (isLoading) {
    return (
      <ModalWrapper>
        <Typography variant="h6">
          Edit Roles at {modifyRolesInfo.companyName}
        </Typography>
        <Container />
        <ExitModal onClick={() => setModifyRolesInfo(null)} />
      </ModalWrapper>
    );
  }

  if (error) {
    return (
      <ModalWrapper>
        <Typography variant="h6">
          Edit your roles at {modifyRolesInfo.companyName}
        </Typography>
        <Typography variant="subtitle1">error...</Typography>
      </ModalWrapper>
    );
  }

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await API.put("/teams/roles/", {
        version: 2,
        team_member_id: team.id,
        role_ids: selectedRoles,
      });
      await queryClient.invalidateQueries("profile");
      await queryClient.invalidateQueries("users");
      await queryClient.invalidateQueries("teams");
      await queryClient.invalidateQueries("allModulesV2", {
        refetchInactive: true,
      });
      setIsSaving(false);
      setSnack(`Updated roles at ${team?.company__name}`, SNACK_TYPES.SUCCESS);
      return setModifyRolesInfo(null);
    } catch (err) {
      setIsSaving(false);
      setSnack("Failed to update roles", SNACK_TYPES.ERROR);
    }
  };

  return (
    <ModalWrapper onClick={(e) => e.stopPropagation()}>
      <Typography style={{ fontWeight: 600, fontSize: 16 }}>
        Edit Roles At {modifyRolesInfo.companyName}
      </Typography>
      <Typography variant="subtitleSmall" sx={{ mb: 4 }}>
        Select your roles depending on what you do at your company
      </Typography>
      <Container>
        {data.map((role) => (
          <RoleContainer
            style={{
              background: selectedRoles.includes(role.id)
                ? "aliceblue"
                : "white",
              border: selectedRoles.includes(role.id)
                ? "1px solid #337ab7"
                : "1px solid lightgray",
            }}
            key={role.id}
            onClick={() => {
              setSelectedRoles((p) => {
                if (p.includes(role.id)) {
                  return p.filter((roleId) => roleId !== role.id);
                }
                return [...p, role.id];
              });
            }}
          >
            <span
              style={{
                fontSize: 13,
                fontWeight: selectedRoles.includes(role.id) ? "500" : "400",
              }}
            >
              {role.name}
            </span>
            <Checkbox size="small" checked={selectedRoles.includes(role.id)} />
          </RoleContainer>
        ))}
      </Container>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: "4px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={!selectedRoles || selectedRoles.length === 0 || isSaving}
          onClick={handleSave}
          endIcon={
            isSaving ? (
              <CircularProgress size={16} style={{ color: "white" }} />
            ) : null
          }
        >
          {isSaving ? "Saving" : "Save"}
        </Button>
      </div>

      <ExitModal onClick={() => setModifyRolesInfo(null)} />
    </ModalWrapper>
  );
};

export default ModifyRolesModal;
