import React, { useState, useMemo } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import {
  Button,
  CircularProgress,
  Typography,
  IconButton,
  Modal,
} from "@mui/material";
import styled from "styled-components/macro";
import { ChevronRight, ChevronLeft, Maximize2 } from "react-feather";
import ShowPdfFullScreen from "./ShowPdfFullScreen";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Wrapper = styled.div`
  display: flex;
  max-width: 100vw;
  flex-direction: column;
  margin: auto;
  background: ${(props) => props.theme.palette.background.contrast};
  padding: 12px;
  border-radius: 6px;
  position: relative;
  border: 1px solid lightgray;
`;

const RelativeIconButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1000;
  cursor: pointer;
  background: #eeeeee98;
  border-radius: 4px;

  &:hover {
    background: ${(props) => props.theme.palette.grey[200]};
  }

  svg {
    height: 16px;
    width: 16px;
    color: black;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 4px;
  align-items: center;
  justify-content: center;
`;

const LoadingPdf = styled.div`
  background-color: white;
  border-radius: 0;
  display: flex;
  width: ${(props) => props.height * 0.65}px;
  height: ${(props) => props.height}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalWrapper = styled.div`
  position: fixed;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
`;

const getSrc = (src) => src;

const LoadingThePdf = ({ height }) => (
  <LoadingPdf height={height}>
    <CircularProgress color="primary" size={30} />
  </LoadingPdf>
);

const PdfError = ({ height }) => (
  <LoadingPdf height={height}>
    <Typography variant="subtitle1" color="warning">
      Error Loading Pdf
    </Typography>
  </LoadingPdf>
);

const ShowPdf = ({ file, width }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isFullSize, setIsFullSize] = useState(false);

  const SRC = useMemo(() => getSrc(file), [file]);

  const onDocumentLoadSuccess = ({ numPages: numberPages }) => {
    setNumPages(numberPages);
  };

  const estimatedHeight = width * 1.5;

  return (
    <>
      <Wrapper>
        <RelativeIconButton
          size="small"
          color="secondary"
          onClick={() => setIsFullSize(true)}
        >
          {!isFullSize && <Maximize2 />}
        </RelativeIconButton>
        <Document
          onLoadSuccess={onDocumentLoadSuccess}
          file={SRC}
          loading={<LoadingThePdf height={estimatedHeight} />}
          error={<PdfError height={estimatedHeight} />}
        >
          <Page pageNumber={pageNumber} width={width} orientation="portrait" />
        </Document>
        <Typography variant="body1" align="center">
          Page {pageNumber} of {numPages || 1}
        </Typography>
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setPageNumber((p) => p - 1)}
            disabled={numPages === 1 || !numPages || pageNumber === 1}
            startIcon={<ChevronLeft />}
            sx={{ mr: 1 }}
          />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setPageNumber((p) => p + 1)}
            disabled={numPages === 1 || !numPages || pageNumber === numPages}
            endIcon={<ChevronRight />}
            sx={{ ml: 1 }}
          />
        </ButtonContainer>
      </Wrapper>
      <Modal
        open={isFullSize}
        onClose={() => {
          setIsFullSize(false);
        }}
      >
        <ModalWrapper>
          <ShowPdfFullScreen setIsFullSize={setIsFullSize} SRC={SRC} />
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default ShowPdf;
