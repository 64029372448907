import { InputAdornment } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { AlertTriangle, CheckCircle, Mail } from "react-feather";
import API from "../../../axios/API";
import { CustomTextField } from "../../../styles/forms";
import { STATUS_OPTIONS } from "../AcceptInvite/helpers";

const EmailField = ({
  values,
  touched,
  handleChange,
  errors,
  handleBlur,
  emailStatus,
  setEmailStatus,

  disabled = false,
  verifyEmail = false,
}) => {
  const [emailCheckLoading, setEmailCheckLoading] = useState(false);

  useEffect(() => {
    if (verifyEmail && !errors.email && values.email) {
      checkForEmail(values.email);
    }
  }, []);

  const checkForEmail = async (checkEmail) => {
    setEmailCheckLoading(true);
    try {
      const { data } = await API.get(
        `/common/account/check-for-existence/?username=${checkEmail}`,
      );
      const { exists } = data;
      setEmailStatus(exists ? STATUS_OPTIONS.DENIED : STATUS_OPTIONS.CONFIRMED);
      return setEmailCheckLoading(false);
    } catch (error) {
      setEmailCheckLoading(false);
      return undefined;
    }
  };

  return (
    <CustomTextField
      type="email"
      name="email"
      label="Email / Username"
      autoComplete="off"
      value={values.email}
      onChange={(e) => {
        if (verifyEmail) {
          setEmailStatus(STATUS_OPTIONS.UNKNOWN);
        }
        handleChange(e);
      }}
      onBlur={(e) => {
        if (!errors.email && verifyEmail) {
          checkForEmail(values.email);
        }
        handleBlur(e);
      }}
      error={Boolean(
        (touched.email && errors.email) ||
          emailStatus === STATUS_OPTIONS.DENIED,
      )}
      helperText={
        touched.email && errors.email
          ? errors.email
          : emailStatus === STATUS_OPTIONS.DENIED && verifyEmail
            ? "There is already an account with this email"
            : null
      }
      fullWidth
      disabled={disabled}
      variant="outlined"
      color="secondary"
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Mail style={{ color: disabled ? "darkgrey" : "black" }} />
          </InputAdornment>
        ),
        endAdornment: verifyEmail ? (
          <InputAdornment position="end" style={{ cursor: "pointer" }}>
            {!errors.email &&
              !emailCheckLoading &&
              emailStatus !== STATUS_OPTIONS.UNKNOWN && (
                <motion.div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 14,
                    width: 14,
                    borderRadius: 10,
                  }}
                  animate={{ opacity: 1, scale: 1 }}
                  initial={{ opacity: 0, scale: 0.6 }}
                >
                  {emailStatus === STATUS_OPTIONS.CONFIRMED ? (
                    <CheckCircle style={{ color: "mediumseagreen" }} />
                  ) : (
                    <AlertTriangle style={{ color: "darkorange" }} />
                  )}
                </motion.div>
              )}
          </InputAdornment>
        ) : null,
      }}
      sx={{
        mb: 4,
      }}
    />
  );
};

export default EmailField;
