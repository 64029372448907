import React from "react";
import { Check, Tag } from "react-feather";
import styled from "styled-components/macro";

const TeamContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  background: #eeeeee;
  cursor: pointer;
  transition: border: 200ms ease-in;
  border: ${(props) =>
    props.$isActive ? "2px solid #337ab7" : "1px solid transparent"};

  &:hover {
    border: ${(props) =>
      props.$isActive ? "2px solid #337ab7" : "1px solid slategrey"};
  }
`;

const CustomCheckbox = styled.div`
  height: 14px;
  width: 14px;
  padding: 0px;
  background: ${(props) => (props.$active ? "#337ab7" : "white")};
  border-radius: 4px;
  display: grid;
  place-items: center;
  border: 1px solid transparent;
  cursor: pointer;
`;

const RoleTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid darkgrey;
  gap: 4px;
  padding: 2px 6px;

  span {
    font-weight: 400;
  }
`;

const DisplayTeam = ({ team, setLeaveId, leaveId }) => (
  <TeamContainer
    onClick={() => setLeaveId((p) => (p && p === team?.id ? null : team?.id))}
    $isActive={leaveId === team?.id}
  >
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: 4,
        padding: 8,
      }}
    >
      <div
        style={{
          flexBasis: 54,
          height: "100%",
        }}
      >
        <img
          src={team?.company__logo}
          alt="team-logo"
          style={{
            height: 42,
            width: 42,
            background: "white",
            borderRadius: 6,
            objectFit: "contain",
            border: "1px solid lightgray",
          }}
        />
      </div>
      <div
        style={{
          flexGrow: 1,
          height: "100%",
          paddingLeft: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontWeight: "600",
            }}
          >
            {team?.company__name}
          </span>
          <CustomCheckbox $active={team.id == leaveId}>
            <Check
              height={10}
              width={10}
              color={team.id === leaveId ? "white" : "transparent"}
            />
          </CustomCheckbox>
        </div>
        <span style={{ color: "black", fontSize: 12 }}>
          {team?.location__name}
        </span>
        <span style={{ fontSize: 12, color: "black" }}>
          {`${team?.storeAddressLine1}, ${team?.storeAddressCity}, ${team?.storeAddressState}, ${team?.storeAddressCountry}`}
        </span>
        <div style={{ height: 4 }} />

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            gap: 4,
            padding: "2px 0px",
          }}
        >
          {team.tags.map((t) => (
            <RoleTag key={t?.name}>
              <Tag height={10} width={10} color="slategrey" />
              <span style={{ fontSize: 10, color: "slategrey" }}>
                {t?.name}
              </span>
            </RoleTag>
          ))}
        </div>
      </div>
    </div>
  </TeamContainer>
);

export default DisplayTeam;
