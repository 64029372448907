import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Button, Typography } from "@mui/material";
import { isAndroid } from "react-device-detect";
import MOBILEIMAGE from "../../assets/svg/mobile.svg";
import { APPSTORE_LINK, PLAYSTORE_LINK } from "../../constants/secret";

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  background-color: white;
  height 240px;
  display: flex;
  align-items: center;
  flex-direction: column;
  bottom: 0px;
`;

const LinkButton = styled.a`
  height: 40px;
  width: 220px;

  font-size: 16px;
  border-radius: 8px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  span {
    font-size: 16px;
    color: white;
    font-weight: 600;
  }

  &:active {
    background-color: lightgrey;
  }

  @media (max-width: 300px) {
    width: 180px;
  }
`;

const RegisterImg = styled.img`
  height: 46px;
  width: 46px;
  margin-left: 18px;
`;

const RecommendAppModal = ({ setShowPushAppstoreModal }) => (
  <Wrapper>
    <div
      style={{
        borderBottom: "1px solid #EEEEEE",
        paddingBottom: 8,
        marginTop: 30,
        marginBottom: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <Typography style={{ fontSize: 14, color: "darkgrey" }}>
          We see you are on your phone...
        </Typography>
        <Typography style={{ fontSize: 15, fontWeight: 600 }}>
          Spiffy is better on the Mobile App
        </Typography>
      </div>
      <RegisterImg
        src={MOBILEIMAGE}
        // alt="mobile-phone"
      />
    </div>
    <LinkButton
      target="_blank"
      href={isAndroid ? PLAYSTORE_LINK : APPSTORE_LINK}
    >
      <span>Get The App</span>
    </LinkButton>
    <div style={{ height: 12 }} />
    <Button
      onClick={() => setShowPushAppstoreModal(false)}
      variant="outlined"
      color="secondary"
      style={{ height: 40, fontSize: 15, width: 220, borderRadius: 8 }}
    >
      Continue with Browser
    </Button>
  </Wrapper>
);

export default RecommendAppModal;

RecommendAppModal.propTypes = {
  setShowPushAppstoreModal: PropTypes.func.isRequired,
};
