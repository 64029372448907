import {
  Button,
  Checkbox,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import styled from "styled-components/macro";
import API from "../../../../axios/API";
import useAllTeamLocations from "../../../../reactQueryHooks/teams/ useAllTeamLocations";
import useSnack from "../../../../store/snack";

const ModalWrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 240px;
  transform: translate(-50%, -50%);
  width: min(500px, 96vw);
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  border: 2px solid black;
`;

const LocationDiv = styled.div`
  width: 100%;
  padding: 4px 4px;
  margin-bottom: 4px;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;

  &:hover {
    background-color: #eeeeee34;
    cursor: pointer;
  }
`;

const ModifyTeamLocationModal = ({
  teamId,
  teamName,
  setOpenModifyLocation,
}) => {
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const queryClient = useQueryClient();
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;

  const { isLoading, data } = useAllTeamLocations(teamId);

  const handleSaveLocation = async () => {
    setIsSaving(true);
    try {
      await API.put("/teams/location/", {
        tm_id: teamId,
        location_id: selectedLocationId,
      });
      await queryClient.invalidateQueries("profile");
      await queryClient.invalidateQueries("users");
      await queryClient.invalidateQueries("teams");
      await queryClient.invalidateQueries("allModulesV2", {
        refetchInactive: true,
      });
      setSnack(`Updated your location at ${teamName}`, SNACK_TYPES.SUCCESS);
      setIsSaving(false);
      return setOpenModifyLocation(false);
    } catch (error) {
      setIsSaving(false);
      setSnack(
        "Failed to update location. Please try again",
        SNACK_TYPES.ERROR,
      );
    }
  };

  return (
    <ModalWrapper onClick={(e) => e.stopPropagation()}>
      <Typography style={{ fontWeight: 600, fontSize: 16 }}>
        Select New Location for {teamName}
      </Typography>
      <Typography variant="subtitleSmall" sx={{ mb: 4 }}>
        Select the location you work at
      </Typography>
      <div style={{ width: "100%", overflowY: "scroll" }}>
        {!isLoading && data.length === 0 && (
          <div
            style={{
              width: "100%",
              height: 130,
              background: "#EEEEEE",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 4,
              marginBottom: 4,
            }}
          >
            <span style={{ fontSize: 14, color: "darkgrey" }}>
              No locations available
            </span>
          </div>
        )}
        {!isLoading &&
          data.length > 0 &&
          data.map((location) => (
            <LocationDiv
              key={location?.id}
              style={{
                border:
                  location.id !== selectedLocationId
                    ? "1px solid lightgray"
                    : "1px solid #337ab7",
                backgroundColor:
                  location.id !== selectedLocationId ? "white" : "aliceblue",
              }}
              onClick={() => {
                setSelectedLocationId((p) =>
                  p === location.id ? null : location.id,
                );
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    fontSize: 13,

                    fontWeight:
                      location.id === selectedLocationId ? "600" : "400",
                  }}
                >
                  {location.name}
                </span>
                <span
                  style={{
                    fontWeight: "300",
                    color:
                      location.id === selectedLocationId ? "black" : "darkgray",
                    fontSize: 11,
                    lineHeight: 1.1,
                  }}
                >
                  {location.line1}, {location.city__name},{" "}
                  {location.state__name}
                </span>
              </div>

              <Checkbox
                size="small"
                checked={selectedLocationId === location.id}
              />
            </LocationDiv>
          ))}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: "auto",
          paddingTop: 4,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={!selectedLocationId || isSaving}
          onClick={handleSaveLocation}
          endIcon={
            isSaving ? (
              <CircularProgress size={16} style={{ color: "white" }} />
            ) : null
          }
        >
          {isSaving ? "Saving" : "Save"}
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default ModifyTeamLocationModal;
