import { Button, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Check, Tag } from "react-feather";
import styled from "styled-components/macro";
import API from "../../../axios/API";
import {
  ButtonHolder,
  Centered,
  Option,
  ShowSearchResults,
  TitleWrapper,
} from "./styles";

const Wrapper = styled.div`
  padding: 6px 12px;
  width: 100%;
`;

const ResultTag = styled(Tag)`
  color: ${(props) => props.theme.palette.background.bw};
  margin-right: 8px;
  height: 12px;
  width: 12px;
  transform: translateY(2px);
`;

const SelectRoles = ({ state, dispatch, ACTIONS }) => {
  const [availableRoles, setAvailableRoles] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [selected, setSelected] = useState([]);

  const { selectedCompany } = state;

  useEffect(() => {
    const getAvailRoles = async () => {
      setFetching(true);
      try {
        const { data } = await API.get(
          `/teams/search/roles/?company_id=${selectedCompany.id}`,
        );
        setAvailableRoles(
          data.roles.map((role) => {
            return {
              id: role[0],
              name: role[1],
            };
          }),
        );
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };

    getAvailRoles();
  }, []);

  const selectedIds = selected.map((r) => r.id);

  const handleSelectRole = (role) => {
    if (selectedIds.includes(role.id)) {
      return setSelected((p) => p.filter((r) => r.id !== role.id));
    }
    return setSelected((p) => [...p, role]);
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <span style={{ fontSize: 18, fontWeight: "600" }}>
          Select Your Roles
        </span>
      </TitleWrapper>
      <ShowSearchResults>
        {fetching ? (
          <Centered>
            <CircularProgress size={20} color="primary" />
          </Centered>
        ) : (
          availableRoles.map((role) => (
            <Option
              selected={selectedIds?.includes(role?.id) ? "y" : "n"}
              key={role.id}
              onClick={() => handleSelectRole(role)}
            >
              <span>
                <ResultTag color="darkgrey" />
                {role.name}
              </span>

              <div
                style={{
                  height: 13,
                  width: 13,
                  borderRadius: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: selectedIds.includes(role.id)
                    ? "#337ab7"
                    : "#eeeeee",
                }}
              >
                <Check
                  color={
                    selectedIds.includes(role.id) ? "white" : "transparent"
                  }
                  height={10}
                  width={10}
                />
              </div>
            </Option>
          ))
        )}
      </ShowSearchResults>
      <ButtonHolder>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => dispatch({ type: ACTIONS.REMOVE_SELECTED_LOCATION })}
        >
          Go Back
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{ ml: 1 }}
          disabled={selected.length === 0}
          color="primary"
          onClick={() => {
            if (selected) {
              return dispatch({
                type: ACTIONS.ADD_SELECTED_ROLES,
                payload: selected,
              });
            }
            return dispatch({ type: ACTIONS.SET_INDEX, payload: 3 });
          }}
        >
          Next Step
        </Button>
      </ButtonHolder>
    </Wrapper>
  );
};

export default SelectRoles;

SelectRoles.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
};
