import React from "react";
import { useNavigate } from "react-router-dom";
import BRAND_SVG from "../../../assets/svg/brands.svg";
import TEAM_SVG from "../../../assets/svg/teams.svg";
import DisplayModulesV3 from "./UI/DisplayModulesV3";
import DisplayStreamsV3 from "./UI/DisplayStreamsV3";
import styled from "styled-components/macro";
import { motion } from "framer-motion";

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eeeeee;
  padding-bottom: 10px;
  background: white;
  box-shadow: 0px 0px 5px 1px lightgray;
  border: 1px solid lightgray;
`;

const Header = styled.div`
  width: 100%;
  background: #eeeeee;
  border-radius: 6px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
`;

const HeaderImg = styled.img`
  height: 44px;
  width: 44px;
  background: white;
  border: 1px solid lightgray;
  border-radius: 6px;
  object-fit: contain;
`;

const TeamOrBrandChip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  border-radius: 3px;
  background: lightgray;
  margin-right: 4px;
  margin-left: auto;
  gap: 4px;
`;

const ScrollableRowV2 = ({ item, getNextModuleInfo }) => {
  const navigate = useNavigate();
  const hasModules = item?.quizzes.length > 0;
  const hasStreams = item?.streams?.length > 0;

  return (
    <Wrapper as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Header>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 8,
          }}
        >
          <HeaderImg src={item.logo} />
          <span style={{ fontWeight: "600", fontSize: 18 }}>{item.name}</span>
        </div>
        <TeamOrBrandChip>
          <img
            src={item?.isTeam ? TEAM_SVG : BRAND_SVG}
            alt="logo"
            style={{
              objectFit: "contain",
              height: item?.isTeam ? 16 : 11,
              width: item?.isTeam ? 16 : 11,
              borderRadius: 6,
            }}
          />

          <span
            style={{
              textTransform: "uppercase",
              color: "slategrey",
              fontSize: 10,
            }}
          >
            {item?.isTeam ? "Team" : "Brand"}{" "}
          </span>
        </TeamOrBrandChip>
      </Header>

      <div
        style={{
          padding: 4,
        }}
      >
        {hasStreams &&
          item.streams.map((stream) => (
            <DisplayStreamsV3
              stream={stream}
              navigate={navigate}
              key={stream?.name}
              getNextModuleInfo={getNextModuleInfo}
              companyName={item.name}
              companyId={item.id}
              isTeam={item?.isTeam}
            />
          ))}
        {hasModules && (
          <div style={{ padding: "0px 4px" }}>
            <DisplayModulesV3
              modules={item.quizzes}
              navigate={navigate}
              getNextModuleInfo={getNextModuleInfo}
              companyName={item.name}
            />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default ScrollableRowV2;
