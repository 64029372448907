import React, { useState } from "react";
import styled from "styled-components/macro";
import { MoreVertical, Search } from "react-feather";
import { Button, ClickAwayListener, Tooltip } from "@mui/material";
import FilterModuleTypeSelect from "./FilterModuleTypeSelect";
import FilterBrandsSelect from "./FilterBrandsSelect";

const StyledButton = styled(Button)`
  height: 36px;
  border-radius: 8px;
  width: 36px;
  max-width: 36px;
  max-height: 36px;
  min-width: 36px;
  min-height: 36px;
  padding: 0px;
  background: white;
  border: ${(props) =>
    props.$isopen
      ? "2px solid #337ab7"
      : props.$hasFilterApplied
        ? "2px solid black"
        : "1px solid #eeeeee"};

  svg {
    color: black;
    height: 16px;
    width: 16px;
  }
`;

const Wrapper = styled.div`
  margin-left: auto;
  position: relative;
  display: none;
  justify-content: flex-end;

  @media (max-width: 680px) {
    display: flex;
  }
`;

const Container = styled.div`
  min-height: 120px;
  width: 240px;
  padding: 8px;
  position: absolute;
  top: 40px;
  right: 0px;
  background: white;
  border: 1px solid lightgray;
  box-shadow: 1px 1px 3px 1px gainsboro;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
`;

const DropDownMenu = ({
  showModules,
  setShowModules,
  companyFilter,
  setCompanyFilter,
  allTeams,
  allBrands,
  setSearchOpen,
}) => {
  const [open, setOpen] = useState(false);

  const hasfilterApplied = companyFilter?.id !== 0 || showModules !== "all";

  return (
    <Wrapper>
      <Tooltip title="Filters" arrow>
        <StyledButton
          $hasFilterApplied={hasfilterApplied}
          $isopen={open}
          onClick={() => setOpen((p) => !p)}
        >
          <MoreVertical color="black" />
        </StyledButton>
      </Tooltip>
      {open && (
        <ClickAwayListener onClickAway={(e) => setOpen(false)}>
          <div>
            <Container>
              <FilterModuleTypeSelect
                showModules={showModules}
                setShowModules={setShowModules}
                fullWidth
              />
              <FilterBrandsSelect
                companyFilter={companyFilter}
                setCompanyFilter={setCompanyFilter}
                allTeams={allTeams}
                allBrands={allBrands}
                showModules={showModules}
                fullWidth
              />
              <div
                style={{
                  height: 1,
                  width: "100%",
                  background: "#eeeeee",
                  margin: "2px 0px",
                }}
              />
              <Button
                startIcon={<Search color="white" height={14} width={14} />}
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setSearchOpen(true)}
              >
                Search
              </Button>
            </Container>
          </div>
        </ClickAwayListener>
      )}
    </Wrapper>
  );
};

export default DropDownMenu;
