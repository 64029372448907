import React from "react";
import styled from "styled-components/macro";
import { Paper, Typography, Button } from "@mui/material";
import Robot from "../../assets/robottwo.webp";
import { useNavigate } from "react-router";

const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
`;

const ErrorPaper = styled(Paper)`
  padding: 20px;
  width: 380px;
  height: 400px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.background.paper};
`;

const HoldRobot = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
`;

const RobotImg = styled.img`
  height: auto;
  width: 140px;
  object-fit: contain;
  margin-left: 20px;
  transform: translateX(-4px);
`;

const BigOops = styled(Typography)`
  font-size: 40px;
  font-weight: 700;
  color: black;
`;

const PageError = ({
  title = "We had an error loading this page",
  description = "If error persists, you can let us know about it by emailing support@withspiffy.com, or by sending us a note on the Contact Us page",
}) => {
  const navigate = useNavigate();

  return (
    <Centered>
      <ErrorPaper>
        <BigOops align="center">Error</BigOops>
        <HoldRobot>
          <RobotImg src={Robot} alt="robot" />
        </HoldRobot>
        <Typography
          style={{ fontSize: 16, fontWeight: 600 }}
          align="center"
          sx={{ mb: 4 }}
        >
          {title}
        </Typography>
        <Typography style={{ color: "slategrey", textAlign: "center" }}>
          {description}
        </Typography>
        <Button
          style={{ marginTop: "auto" }}
          size="small"
          color="secondary"
          fullWidth
          variant="contained"
          onClick={() => navigate("/")}
        >
          Reload
        </Button>
      </ErrorPaper>
    </Centered>
  );
};

export default PageError;
