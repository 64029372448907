import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Typography, Button, CircularProgress } from "@mui/material";
import { useQueryClient } from "react-query";
import { MapPin, Tag } from "react-feather";
import { ButtonHolder, ShowSearchResults, TitleWrapper } from "./styles";

import API from "../../../axios/API";

import useSnack from "../../../store/snack";
import DynamicTeamsIcon from "../../Header/TeamsDropdown/DynamicTeamsIcon";

const Wrapper = styled.div`
  width: 100%;
  padding: 6px 12px;
`;

const MarginSpan = styled.span`
  margin-right: 8px;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    color: black;
    height: 14px;
    width: 14px;
  }
`;

const OuterFlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  height: 44px;
  border-radius: 5px;
  margin-bottom: 4px;
  background: white;
`;

const SelectionText = styled(Typography)`
  color: black;
  font-weight: 500;
  font-size: 14px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const COMPONENT_STATES = {
  CONFIRMING: "confirming",
  PROCESSING: "processing",
  FINISHED: "finished",
};

const Confirm = ({ state, dispatch, ACTIONS, setAddTeamModalOpen }) => {
  const queryClient = useQueryClient();
  const { snack } = useSnack();
  const { SNACK_TYPES, setSnack } = snack;
  const [componentState, setComponentState] = useState(
    COMPONENT_STATES.CONFIRMING,
  );

  const handleSubmit = async () => {
    setComponentState(COMPONENT_STATES.PROCESSING);
    try {
      await API.post("/teams/search/add/", {
        location_id: state.selectedLocation.id,
        company_id: state.selectedCompany.id,
        roles: state.selectedRoles.map((r) => Number(r.id)),
      });
      await queryClient.invalidateQueries("allModulesV2", {
        refetchInactive: true,
      });
      // queryClient.invalidateQueries("users");
      queryClient.invalidateQueries("teams", { refetchInactive: true });
      setSnack(
        `Company ${state.selectedCompany.name} joined`,
        SNACK_TYPES.SUCCESS,
      );
      setAddTeamModalOpen(false);
    } catch (error) {
      setSnack("Failed to join company", SNACK_TYPES.ERROR);
    }
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <span style={{ fontSize: 18, fontWeight: "600" }}>Confirm Details</span>
      </TitleWrapper>
      <ShowSearchResults>
        <OuterFlexDiv>
          <FlexDiv>
            <DynamicTeamsIcon />
            <Typography variant="subtitle1" sx={{ ml: 2 }}>
              Team:
            </Typography>
          </FlexDiv>
          <SelectionText variant="body1" noWrap sx={{ ml: 2 }}>
            {state.selectedCompany.name}
          </SelectionText>
        </OuterFlexDiv>
        <OuterFlexDiv>
          <FlexDiv>
            <MapPin />
            <Typography variant="subtitle1" sx={{ ml: 2 }}>
              Location:
            </Typography>
          </FlexDiv>
          <SelectionText variant="body1" sx={{ ml: 2 }} noWrap>
            {state.selectedLocation.name}
          </SelectionText>
        </OuterFlexDiv>
        <OuterFlexDiv>
          <FlexDiv>
            <Tag />
            <Typography variant="subtitle1" sx={{ ml: 2 }}>
              Roles:
            </Typography>
          </FlexDiv>
          <SelectionText noWrap sx={{ ml: 2 }}>
            {state.selectedRoles.map((r, i) => (
              <MarginSpan key={r.id}>{`${r.name}${
                i === state.selectedRoles.length - 1 ? "" : ","
              }`}</MarginSpan>
            ))}
          </SelectionText>
        </OuterFlexDiv>
      </ShowSearchResults>
      <ButtonHolder sx={{ mt: 2 }}>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => dispatch({ type: ACTIONS.REMOVE_SELECTED_ROLES })}
        >
          Go Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ ml: 1 }}
          onClick={handleSubmit}
          startIcon={
            componentState === COMPONENT_STATES.PROCESSING ? (
              <CircularProgress size={15} color="primary" />
            ) : null
          }
          disabled={componentState === COMPONENT_STATES.PROCESSING}
        >
          {componentState === COMPONENT_STATES.PROCESSING ? "..." : "Finish"}
        </Button>
      </ButtonHolder>
    </Wrapper>
  );
};

export default Confirm;

Confirm.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
  setAddTeamModalOpen: PropTypes.func.isRequired,
};
