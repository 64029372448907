import React from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { Wrapper, ReviewOption } from "./styles";

const Indicator = styled.div`
  height: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: rgba(51, 122, 183, 0.2);
  border: 1px solid rgba(51, 122, 183, 0.4);

  div {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: rgb(51, 122, 183);
  }
`;

const IndicatorCorrect = styled(Indicator)`
  background-color: rgba(146, 255, 146, 0.18);
  border: 1px solid mediumseagreen;

  div {
    border-radius: 50%;
    background-color: mediumseagreen;
  }
`;

const HoldIndicators = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
`;

const SelectAll = ({ question, userAnswer }) => {
  const parsedAnswer = JSON.parse(userAnswer);

  return (
    <Wrapper>
      {Object.entries(question.options).map((option) => (
        <ReviewOption key={option[0]}>
          <span>{`${option[0].toUpperCase()})  ${option[1]}`}</span>
          <HoldIndicators>
            {parsedAnswer.includes(option[0].toLowerCase()) ? (
              <Indicator>
                <div />
              </Indicator>
            ) : (
              <div />
            )}
            {question.correctAnswer.includes(option[0].toLowerCase()) ||
            question.correctAnswer.includes(option[0].toUpperCase()) ? (
              <IndicatorCorrect>
                <div />
              </IndicatorCorrect>
            ) : (
              <div />
            )}
          </HoldIndicators>
        </ReviewOption>
      ))}
    </Wrapper>
  );
};

export default SelectAll;

SelectAll.propTypes = {
  question: PropTypes.object.isRequired,
  userAnswer: PropTypes.string.isRequired,
};
