import React, { useState } from "react";
import { IconButton, Popover } from "@mui/material";
import { Check, ChevronDown, X } from "react-feather";
import { addLogoString } from "../../../helpers";
import useBrandOverview from "../../../reactQueryHooks/brand/useBrandOverview";
import styled from "styled-components";

const Inner = ({ companyId, streamId }) => {
  const { data, isLoading, isError } = useBrandOverview(companyId);

  if (isLoading) return <div style={{ height: 100, width: 200 }} />;
  if (isError) return <span>error loading data...</span>;

  const targetStream = data?.streams.find((s) => s.id === streamId);

  const numTotal = targetStream?.quizzes?.length;
  const numCompleted = targetStream.quizzes?.filter(
    (mod) => mod.completed,
  ).length;

  return (
    <div
      style={{
        padding: "8px 12px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",

          width: "100%",
        }}
      >
        <span style={{ fontWeight: "600", fontSize: 13 }}>
          {targetStream?.name}
        </span>
      </div>
      <div style={{ height: 6 }} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 6,
          maxWidth: 500,
          width: "100%",
          overflowX: "scroll",
          padding: "4px 0px",
        }}
      >
        {targetStream?.quizzes?.map((mod) => (
          <div
            key={mod?.id}
            style={{
              height: 46,
              minWidth: 46,
              width: 46,

              borderRadius: 5,
              position: "relative",
            }}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                border: "1px solid lightgray",
                borderRadius: 4,
                objectFit: "cover",
                filter: mod?.completed ? "none" : "grayscale(1)",
              }}
              alt="module-art"
              src={addLogoString(mod.image)}
            />
            <div
              style={{
                position: "absolute",
                top: -2,
                right: -2,
                background: mod?.completed ? "seagreen" : "slategrey",
                height: 12,
                width: 12,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {mod?.completed ? (
                <Check height={10} width={10} color="white" />
              ) : (
                <X height={10} width={10} color="white" />
              )}
            </div>
          </div>
        ))}
      </div>
      <div style={{ height: 8 }} />

      <span style={{ color: "slategray", fontWeight: 500, fontSize: 11 }}>
        <b>{numCompleted} </b>
        of
        <b> {numTotal} </b>
        modules completed
      </span>
    </div>
  );
};

const CustomIconButton = styled(IconButton)`
  background: #eeeeee80;
  height: 18px;
  width: 18px;
  padding: 0px;
  border-radius: 4px;

  &:hover {
    background: #eeeeee;
  }
`;

const DisplayStreamPopover = ({ companyId, streamId }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  return (
    <div style={{ position: "relative" }}>
      <CustomIconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <ChevronDown height={12} width={12} />
      </CustomIconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Inner companyId={companyId} streamId={streamId} />
      </Popover>
    </div>
  );
};

export default DisplayStreamPopover;
