import * as Yup from "yup";

export const INVITATION_TYPES_V2 = {
  EXISTING_USER_NO_ROLES: "EXISTING_USER_NO_ROLES",
  EXISTING_USER_WITH_ROLES: "EXISTING_USER_WITH_ROLES",
  NEW_USER_NO_ROLES: "NEW_USER_NO_ROLES",
  NEW_USER_WITH_ROLES: "NEW_USER_WITH_ROLES",
};

export const INVITATTION_TYPES_NEW_USER = [
  INVITATION_TYPES_V2.NEW_USER_NO_ROLES,
  INVITATION_TYPES_V2.NEW_USER_WITH_ROLES,
];

export const STATUS_OPTIONS = {
  CONFIRMED: "CONFIRMED",
  DENIED: "DENIED",
  UNKNOWN: "UNKNOWN",
};

export const getInviteType = (data) => {
  const hasUser = data.user_exists;
  const hasRoles = !Object.keys(data).includes("available_roles");

  if (hasUser && !hasRoles) return INVITATION_TYPES_V2.EXISTING_USER_NO_ROLES;
  if (hasUser && hasRoles) return INVITATION_TYPES_V2.EXISTING_USER_WITH_ROLES;
  if (!hasUser && !hasRoles) return INVITATION_TYPES_V2.NEW_USER_NO_ROLES;
  if (!hasUser && hasRoles) return INVITATION_TYPES_V2.NEW_USER_WITH_ROLES;
};

const NEW_USER_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  firstName: Yup.string()
    .required("First name is required")
    .min(2, "Minimum 2 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .min(2, "Minimum 2 characters"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password should be at least 8 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .required("Confirm password is required")
    .min(8, "Password should be at least 8 characters"),
  dob: Yup.number().required("Date of birth is required"),
  location: Yup.number().required("Location is a required field"),
  roles: Yup.array().required("Role is a required field"),
  country: Yup.string().required(),
  state: Yup.number().required(),
});

const EXISTING_USER_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  firstName: Yup.string()
    .required("First name is required")
    .min(1, "Minimum 1 character"),
  lastName: Yup.string()
    .required("Last name is required")
    .min(1, "Minimum 1 character"),
  location: Yup.number().required("Location is a required field"),
  roles: Yup.array().required("Role is a required field"),
});

export const getValidationSchema = (inviteType) => {
  if (
    [
      INVITATION_TYPES_V2.NEW_USER_NO_ROLES,
      INVITATION_TYPES_V2.NEW_USER_WITH_ROLES,
    ].includes(inviteType)
  ) {
    return NEW_USER_SCHEMA;
  }

  return EXISTING_USER_SCHEMA;
};

export const getInitialValues = (inviteType, data) => {
  if (inviteType === INVITATION_TYPES_V2.NEW_USER_NO_ROLES) {
    return {
      email: data?.email || "",
      firstName: data?.first_name || "",
      lastName: data?.last_name || "",
      password: "",
      confirmPassword: "",
      dob: "",
      location: data?.locations[0]?.id || "",
      roles: [],
      // roles: "test-failing-roles",

      country: "",
      state: "",
    };
  }

  if (inviteType === INVITATION_TYPES_V2.NEW_USER_WITH_ROLES) {
    return {
      email: data?.email || "",
      firstName: data?.first_name || "",
      lastName: data?.last_name || "",
      password: "",
      confirmPassword: "",
      dob: "",
      location: data?.locations[0]?.id || "",
      roles: data?.roles.map((role) => role.id),
      country: "",
      state: "",
    };
  }

  if (inviteType === INVITATION_TYPES_V2.EXISTING_USER_NO_ROLES) {
    return {
      email: data?.email || "",
      firstName: data?.first_name || "",
      lastName: data?.last_name || "",
      location: data.locations[0]?.id || "",
      roles: [],
    };
  }

  if (inviteType === INVITATION_TYPES_V2.EXISTING_USER_WITH_ROLES) {
    return {
      email: data?.email || "",
      firstName: data?.first_name || "",
      lastName: data?.last_name || "",
      location: data.locations[0]?.id || "",
      roles: data?.roles.map((role) => role.id),
    };
  }

  return null;
};

export const getInviteDataFailedErrorMsg = (err) => {
  try {
    const errors = err?.response?.data?.error;

    const context = errors.context?.errors.join(". \n");

    return {
      title: errors?.title || "Error",
      body: errors?.details,
      context: context || null,
    };
  } catch (error) {
    return {
      title: "Error",
      body: "There was an error loading this invitation",
      context: null,
    };
  }
};

export const getAcceptInviteFailedErrorMsg = (err) => {
  try {
    const errors = err?.response?.data?.error;

    const context = errors.context?.errors.join(". \n");

    return {
      title: errors?.title,
      body: errors?.details,
      context: context || null,
    };
  } catch (e) {
    return {
      title: "Error",
      body: "There was an error submitting this invitation.",
      context: null,
    };
  }
};
