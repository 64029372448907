import styled from "styled-components/macro";
import { CheckCircle } from "react-feather";

export const SelectedIcon = styled(CheckCircle)`
  color: ${(props) => props.theme.palette.success.light};
  height: 14px;
  width: 14px;
  margin-right: 8px;
`;

export const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
`;

export const ShowSearchResults = styled.div`
  width: 100%;
  max-height: 300px;
  min-height: 300px;

  height: 300px;
  padding: 6px;
  background: #eeeeee;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 8px;
  cursor: pointer;
  gap: 3px;
  border: 1px solid lightgray;
`;

export const Option = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${(props) =>
    props.selected === "y" ? "1px solid #337ab7" : "1px solid lightgray"};
  border-radius: 4px;
  padding: 12px 8px;
  background: white;

  span {
    color: ${(props) => props.theme.palette.background.bg};
  }

  &:hover {
    background-color: aliceblue;
  }
`;

export const Centered = styled.div`
  width: 100%;
  height: 100%;
  min-height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;

  justify-content: space-between;
`;
