import React from "react";
import ModuleCardV3 from "../UI/ModuleCardV3";
import { Flag } from "react-feather";
import RightButton from "./RightButton";
import LeftButton from "./LeftButton";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import DisplayText from "./DisplayText";
import useWindowSize from "../../../../hooks/useWindowSize";
import DisplayStreamPopover from "../DisplayStreamPopover";

const DisplayStreamName = ({
  name,
  companyName,
  streamId,
  companyId,
  isTeam,
}) => (
  <div
    style={{
      flexGrow: 0,
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      flexDirection: "column",
      overflowY: "hidden",
      textOverflow: "ellipsis",
      paddingLeft: 8,
      padding: "4px",
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 4,
        overflow: "hidden",
        textOverflow: "ellipsis",

        width: "100%",
      }}
    >
      <Flag
        color="black"
        height={20}
        width={20}
        style={{ padding: 4, borderRadius: 10, background: "#eeeeee" }}
      />
      <span
        style={{
          fontSize: 14,
          color: "darkgrey",
          fontWeight: "500",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        Stream from {companyName} /
      </span>
      <span
        style={{
          fontWeight: "500",
          whiteSpace: "nowrap",
          fontSize: 14,
          color: "black",
          maxWidth: 280,
          overflow: "hidden",
          textOverflow: "ellipsis",
          paddingLeft: 4,
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </span>
      {!isTeam && (
        <DisplayStreamPopover streamId={streamId} companyId={companyId} />
      )}
    </div>
  </div>
);

const DisplayStreamsV3 = ({
  stream,
  navigate,
  getNextModuleInfo,
  companyName,
  companyId,
  isTeam,
}) => {
  const { width } = useWindowSize();

  return (
    <div
      style={{
        width: "100%",
        padding: "10px 4px",
        marginBottom: 24,
        borderBottom: "1px solid #eeeeee",
      }}
    >
      <ScrollMenu
        Header={
          <DisplayStreamName
            streamId={stream?.id}
            name={stream.name}
            companyName={companyName}
            companyId={companyId}
            isTeam={isTeam}
          />
        }
        Footer={
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 10,
              marginTop: 14,
            }}
          >
            <LeftButton />

            {width > 500 && (
              <DisplayText numModules={stream?.quizzes?.length} />
            )}
            <RightButton />
          </div>
        }
      >
        {stream.quizzes.map((item) => (
          <ModuleCardV3
            key={item.id}
            itemId={item.id}
            mod={item}
            navigate={navigate}
            getNextModuleInfo={getNextModuleInfo}
          />
        ))}
      </ScrollMenu>
    </div>
  );
};

export default DisplayStreamsV3;
