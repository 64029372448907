import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Paper, Typography } from "@mui/material";
import { ExitModal } from "../../styles/buttons";
import MOBILE from "../../assets/svg/mobile.svg";
import AppStoreBtn from "../../assets/appstore-black.svg";
import GoolePlayBtn from "../../assets/google-play-badge.png";

const Wrapper = styled(Paper)`
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  padding: 20px;
  min-height: 330px;
  border: 2px solid black;
  width: 620px;
  background: ${(props) => props.theme.palette.background.paper};
  z-index: 1000000;

  @media (max-width: 640px) {
    width: 96vw;
  }
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
  min-height: 170px;
  text-align: left;
`;

const RegisterImg = styled.img`
  margin-top: auto;
  height: 120px;
  width: 120px;

  @media (max-width: 640px) {
    display: none;
  }
`;

const AlignImages = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

const CustomImgApple = styled.img`
  width: 100px;
`;

const CustomImgGooglePlay = styled.img`
  width: 120px;
  height: 48px;
`;

const HyperLink = styled.a`
  text-decoration: none;
`;

const Subtitle = styled.p`
  font-size: 15px;
  font-weight: 600;
`;

const BodyText = styled.p`
  font-size: 13px;
  font-weight: 400;
  padding-left: 18px;
`;

const NewRegisterModal = ({ setShowRegisterModal }) => (
  <Wrapper>
    <ExitModal onClick={() => setShowRegisterModal(false)} />
    <Typography variant="h3" align="center">
      Welcome to Spiffy
    </Typography>
    <Typography
      variant="subtitle1"
      align="center"
      sx={{ mb: 3, mt: -1, color: "darkgrey" }}
    >
      A couple tips for getting started! 👇
    </Typography>
    <FlexDiv>
      <Left>
        <Subtitle>
          1) Join your team and get recognized for your progress!
        </Subtitle>
        <BodyText>
          Joining your team unlocks personalized modules designed just for you.
          It’s your chance to earn recognition, level up your skills, and see
          your name rise on the leaderboards! Look out for modules marked with:
        </BodyText>
        <div style={{ height: 8 }} />
        <Subtitle>2) Discover and follow your favourite brands!</Subtitle>
        <BodyText>
          Love learning? Explore modules from top brands that will deepen your
          knowledge and give you an edge! Find your next favorite course on the
          Explore Page and see them marked with:
        </BodyText>
        <div style={{ height: 8 }} />

        <Subtitle>3) Take Spiffy on the go with our mobile app!</Subtitle>
        <BodyText>
          Stay ahead wherever you are! Download the Spiffy mobile app for
          seamless learning on iOS or Android. Your path to mastery is just a
          tap away!
        </BodyText>
        <div style={{ height: 12 }} />

        <AlignImages>
          <HyperLink
            target="_blank"
            href="https://apps.apple.com/ca/app/spiffy/id1138634357"
          >
            <CustomImgApple src={AppStoreBtn} alt="Get it on apple appstore" />
          </HyperLink>
          <HyperLink
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.spiffy.app"
          >
            <CustomImgGooglePlay
              src={GoolePlayBtn}
              alt="Get it on google playstore"
            />
          </HyperLink>
        </AlignImages>
      </Left>
      <RegisterImg src={MOBILE} />
    </FlexDiv>
  </Wrapper>
);

export default NewRegisterModal;

NewRegisterModal.propTypes = {
  setShowRegisterModal: PropTypes.func.isRequired,
};
