import React from "react";
import { setToken, removeToken, getToken } from "../../helpers";
import { useState, createContext, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";

const restrictPathnamesArr = ["referral", "invitation", "register"];

const authContext = createContext();

export const AuthProvider = ({ children }) => {
  const auth = useAuthProvider();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const useAuthProvider = () => {
  const token = getToken();
  const location = useLocation();
  const { pathname } = location;

  const [authenticated, setAuthenticated] = useState(token ? true : false);
  const [inboundPath, setInboundPath] = useState(null);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  useEffect(() => {
    // if user has a token but is following a link to the invite or referral page,
    // log the user out and push to that page

    const token = getToken();

    if (
      pathname.includes(restrictPathnamesArr[0]) ||
      pathname.includes(restrictPathnamesArr[1])
    ) {
      removeToken();
      setAuthenticated(false);
      return navigate(`${pathname}${location?.search}`);
    }

    // on refresh, checks if there is a token, if there is, you are authenticated
    if (!token) return setAuthenticated(false);
  }, [token]);

  useEffect(() => {
    setInboundPath(pathname);
  }, []);

  const login = (token) => {
    setToken(token);
    setAuthenticated(true);
    navigate(
      inboundPath && !inboundPath.includes("invitation") ? inboundPath : "/",
    );
    return setInboundPath(null);
  };

  const logout = (logoutState = null) => {
    removeToken();
    setAuthenticated(false);
    queryClient.clear();
    setInboundPath(null);
    if (logoutState) {
      return navigate("/signin", { state: logoutState });
    }
    return navigate("/signin");
  };

  return {
    authenticated,
    login,
    logout,
  };
};

const useAuth = () => {
  return useContext(authContext);
};

export default useAuth;
