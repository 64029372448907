import React from "react";
import styled from "styled-components/macro";
import { colors } from "../../../../theme/colors";

const Container = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 4px;
  right: 4px;
`;

const DisplayModuleTypeChip = ({ isAssigned = true }) => {
  if (isAssigned) {
    return (
      <Container>
        <div style={{ background: "white", borderRadius: 6 }}>
          <div
            style={{
              height: 24,
              width: 24,
              borderRadius: 6,
              background: colors.assignedBg,
              border: `1px solid ${colors.assignedText}`,

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontWeight: "700", color: colors.assignedText }}>
              A
            </span>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div
        style={{
          height: 24,
          width: 24,
          borderRadius: 6,
          background: colors.extraCreditBg,
          border: `1px solid ${colors.extraCreditText}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ fontWeight: "700", color: colors.extraCreditText }}>
          E
        </span>
      </div>
    </Container>
  );
};

export default DisplayModuleTypeChip;
