import React, { useState, useEffect } from "react";
import { CustomTextField } from "../../../styles/forms";
import { InputAdornment, Tooltip } from "@mui/material";
import { Archive, AlertTriangle, CheckCircle } from "react-feather";
import API from "../../../axios/API";
import { motion } from "framer-motion";
import { STATUS_OPTIONS } from "../AcceptInvite/helpers";

const ReferralCodeField = ({
  touched,
  values,
  handleBlur,
  handleChange,
  errors,
  referralStatus,
  setReferralStatus,

  verifyCode = false,
  disabled = false,
  isOptional = true,
}) => {
  const [referralCheckLoading, setReferralCheckLoading] = useState(false);

  useEffect(() => {
    if (verifyCode && !errors.ref && values.ref) {
      checkForReferralCode(values.ref);
    }
  }, []);

  const checkForReferralCode = async (referralCode) => {
    try {
      setReferralCheckLoading(true);
      const { data } = await API.get(
        `/common/referral/verify/?code=${referralCode}`,
      );
      const { exists } = data;

      setReferralCheckLoading(false);
      setReferralStatus(
        exists ? STATUS_OPTIONS.CONFIRMED : STATUS_OPTIONS.DENIED,
      );
    } catch (error) {
      setReferralCheckLoading(false);
    }
  };

  return (
    <CustomTextField
      label="Referral Code"
      type="text"
      name="ref"
      variant="outlined"
      value={values.ref}
      color="secondary"
      onBlur={(e) => {
        if (values.ref.length < 1) {
          return setReferralStatus(STATUS_OPTIONS.UNKNOWN);
        }
        if (!errors.ref && verifyCode) {
          checkForReferralCode(values.ref);
        }
        handleBlur(e);
      }}
      error={Boolean(touched.ref && errors.ref)}
      onChange={handleChange}
      helperText={isOptional ? "*optional" : ""}
      disabled={disabled}
      fullWidth
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Archive style={{ color: disabled ? "darkgrey" : "black" }} />
          </InputAdornment>
        ),
        endAdornment: verifyCode ? (
          <InputAdornment position="end" style={{ cursor: "pointer" }}>
            {!errors.ref &&
              values.ref.length > 0 &&
              !referralCheckLoading &&
              referralStatus !== STATUS_OPTIONS.UNKNOWN && (
                <Tooltip
                  title={
                    referralStatus === STATUS_OPTIONS.CONFIRMED
                      ? "Referall code verified"
                      : "Referral code does not exist in the database"
                  }
                  arrow
                >
                  <motion.div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 14,
                      width: 14,
                      borderRadius: 10,
                    }}
                    animate={{ opacity: 1, scale: 1 }}
                    initial={{ opacity: 0, scale: 0.6 }}
                  >
                    {referralStatus === STATUS_OPTIONS.CONFIRMED ? (
                      <CheckCircle style={{ color: "mediumseagreen" }} />
                    ) : (
                      <AlertTriangle style={{ color: "darkorange" }} />
                    )}
                  </motion.div>
                </Tooltip>
              )}
          </InputAdornment>
        ) : null,
      }}
      sx={{
        mb: 4,
      }}
    />
  );
};

export default ReferralCodeField;
