import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import overrides from "./overrides";
import typography from "./typography";

const SPIFFY_BLUE = "#337ab7";

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1440,
  },
};

const props = {
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiButton: {
    disableRipple: true,
  },
};

const theme = createTheme({
  spacing: 4,
  breakpoints,
  components: overrides,
  props,
  typography,
  palette: {
    mode: "light",
    primary: {
      light: "rgba(0, 0, 0, .6)",
      main: SPIFFY_BLUE,
      brand: "rgb(51, 122, 183)",
    },
    secondary: {
      main: "rgb(0, 0, 0)",
    },
    action: {
      main: "mediumseagreen",
    },
    nav: {
      background: grey[300],
      toolbar: "#FFF",
      activeLink: grey[400],
    },
    background: {
      main: "#F7F9FC",
      contrast: grey[200],
      wb: "#FFF",
      bw: "#000",
      bg: "#000",
      paper: "#FFF",
      light: grey[100],
    },
    loader: "#000",
    boxShadow: {
      main: "1px 1px 6px 1px rgba(0, 0, 0, .12)",
    },
    option: {
      // selected: '#DAE3ED',
      selected: "#94bee22b",
    },
  },
});

const getTheme = () => theme;

export default getTheme;
